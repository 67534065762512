import {
  Avatar,
  Badge,
  Box,
  Link,
  Stack,
  styled,
  Typography,
  useTheme
} from '@mui/material';
import { FC } from 'react';
import SocialMediaIcon from 'src/components/SocialMediaIcon';
import { TSocialMedia } from 'src/models/objectManagement';
import { getSocialMediaColor } from 'src/utils/getSocialMediaColor';

interface Props {
  originalId: string;
  image?: string | undefined;
  account?: string | undefined;
  status?: string | undefined;
  link?: string | undefined;
  socialMedia?: TSocialMedia;
}

const Container = styled(Box)(
  ({ theme }) => `
    margin: ${theme.spacing(1, 0)};
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 4px 4px 8px;

    border: 1px solid #F5F5F5;
    box-sizing: border-box;
    border-radius: 9px;
      `
);

const ContentStream: FC<Props> = ({
  originalId,
  image,
  account,
  status,
  link,
  socialMedia
}) => {
  const theme = useTheme();

  return (
    <Container>
      <Stack
        direction="row"
        justifyContent="space-between"
        sx={{ width: '100%' }}
      >
        <Stack spacing={1} direction="row" alignItems="center" sx={{ mb: 1 }}>
          <Badge
            overlap="circular"
            badgeContent={
              <SocialMediaIcon
                socialMedia={socialMedia}
                sx={{
                  width: theme.spacing(2),
                  height: theme.spacing(2),
                  color: getSocialMediaColor(socialMedia)
                }}
              />
            }
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right'
            }}
          >
            <Avatar alt={account} src={image} sx={{ width: 26, height: 26 }} />
          </Badge>
          <Typography variant="subtitle2" sx={{ fontWeight: 600 }}>
            {account}
          </Typography>
        </Stack>
      </Stack>
      <Typography
        variant="subtitle2"
        sx={{
          fontWeight: 300,
          display: '-webkit-box',
          WebkitLineClamp: 2,
          WebkitBoxOrient: 'vertical',
          overflow: 'hidden',
          fontSize: '13px',
          wordBreak: 'break-all'
        }}
      >
        {status}
      </Typography>
      <Link
        href={link}
        target="_blank"
        rel={account}
        sx={{ mt: theme.spacing(0.5), fontSize: '13px' }}
      >
        Original Post
      </Link>
    </Container>
  );
};

export default ContentStream;
