import gql from 'graphql-tag';

const analyticArgumentsQuery = `
  $projectId: Id!,
  $comparisonId: Id!,
  $start: Timestamp!, 
  $end: Timestamp!, 
  $objectList: [Id!],
  $granularity: GranularityInput!, 
  $timezone: Int!, 
  $phrase: String, 
  $sentiment: [Sentiment!], 
  $postOwnership: [PostOwnership!],
  $labelList: [Id!],
  $validationList: [StreamValidation!],
  $contentTypeList: [ContentType!],
  $includeKeywords: [String!],
  $excludeKeywords: [String!],
  $fromUsername: String!,
  $showEmptyLabel: Boolean,
  $replyToOriginalId: [String!],
  $sentimentConfidenceLevelMin:BigDecimal!
  $sentimentConfidenceLevelMax:BigDecimal!
  $excludeRt: Boolean
  `;

const analyticArguments = `
  projectId: $projectId, 
  comparisonId: $comparisonId,
  start: $start, 
  end: $end,
  objectList: $objectList,
  granularity: $granularity, 
  timezone: $timezone, 
  phrase: $phrase, 
  sentiment: $sentiment, 
  postOwnership: $postOwnership,
  labelList: $labelList,
  validationList: $validationList,
  contentTypeList: $contentTypeList,
  includeKeywords: $includeKeywords,
  excludeKeywords: $excludeKeywords,
  fromUsername: $fromUsername,
  showEmptyLabel: $showEmptyLabel,
  replyToOriginalId: $replyToOriginalId,
  sentimentConfidenceLevelMin:$sentimentConfidenceLevelMin,
  sentimentConfidenceLevelMax:$sentimentConfidenceLevelMax,
  excludeRt: $excludeRt`;

const GET_NAMESPACE_LIST = gql`
  query comparison_getComparisonNamespaceList($projectId: Id!) {
    comparison_getComparisonNamespaceList(projectId: $projectId) {
      id
      namespace
      displayName
      isDefault
      sourceType
      createdAt
      colors
    }
  }
`;

const GET_COMPARISON_ENTITY = gql`
  query comparison_getComparisonEntity($comparisonId: Id!) {
    comparison_getComparisonEntity(comparisonId: $comparisonId) {
      id
      namespace
      displayName
      createdAt
      colors
      groups {
        id
        name
        displayName
        objectGroup {
          id
          name
          displayName
          createdAt
          objectList {
            id
            socialMedia
            streamType
            extraContent
            objectName
            isHashtag
          }
        }
        objectList {
          id
          socialMedia
          streamType
          extraContent
          objectName
          isHashtag
        }
        labelList {
          id
          name
          displayName
          colorType
        }
        createdAt
      }
    }
  }
`;

const GET_COMPARISON_DEFAULT = gql`
  query comparison_getComparisonDefault($projectId: Id!) {
    comparison_getComparisonDefault(projectId: $projectId) {
      id
      namespace
      displayName
      sourceType
      isDefault
      createdAt
    }
  }
`;

const GET_BRAND_SENTIMENT = gql`
  query comparison_getBrandSentiment(${analyticArgumentsQuery}) {
    comparison_getBrandSentiment(${analyticArguments}) {
      groupName
      positive
      negative
      neutral
    }
  }
`;

const GET_BRAND_POPULARITY = gql`
  query comparison_getBrandPopularity(${analyticArgumentsQuery}) {
    comparison_getBrandPopularity(${analyticArguments}) {
      name
      value
    }
  }
`;

const GET_BRAND_POSITIONING = gql`
  query comparison_getBrandPositioning(${analyticArgumentsQuery}) {
    comparison_getBrandPositioning(${analyticArguments}) {
      name
      popularity
      positivity
    }
  }
`;

const GET_CHRONOLOGICAL_AUDIENCE_BY_BRAND = gql`
  query comparison_getChronologicalAudienceByBrand(${analyticArgumentsQuery}) {
    comparison_getChronologicalAudienceByBrand(${analyticArguments}) {
      name
      values{
        timestamp
        value
      }
    }
  }
`;

const GET_CHRONOLOGICAL_TALK_BY_BRAND = gql`
  query comparison_getChronologicalTalkByBrand(${analyticArgumentsQuery}) {
    comparison_getChronologicalTalkByBrand(${analyticArguments}) {
      name
      values{
        timestamp
        value
      }
    }
  }
`;

const GET_CHRONOLOGICAL_ENGAGEMENT_BY_BRAND = gql`
  query comparison_getChronologicalEngagementByBrand(${analyticArgumentsQuery}) {
    comparison_getChronologicalEngagementByBrand(${analyticArguments}) {
      name
      values{
        timestamp
        value
      }
    }
  }
`;

const GET_BRAND_PEAK_TIME = gql`
  query comparison_getBrandPeakTime(${analyticArgumentsQuery}) {
    comparison_getBrandPeakTime(${analyticArguments}) {
      name
      values
    }
  }
`;

const GET_TOP_POSTMADE = gql`
  query comparison_getTopPostMade(${analyticArgumentsQuery}) {
    comparison_getTopPostMade(${analyticArguments}) {
      originalId
      socialMedia
      displayPicture
      fromId
      fromName
      content
      link
      timestamp
      shareCount
      likeCount
      commentCount
      reach
      impression
      engagementRate
      benchmark {
        benchmark
      }
    }
  }
`;

const GET_TOP_POSTMADE_PAGINATION = gql`
  query comparison_getTopPostMadeWithPagination(${analyticArgumentsQuery},$columnName: SortingByColumn, $sortingMethod: SortingType, $next: String) {
    comparison_getTopPostMadeWithPagination(${analyticArguments}, columnName: $columnName, sortingMethod: $sortingMethod, next: $next) {
      result {
        originalId
        socialMedia
        displayPicture
        fromId
        fromName
        content
        link
        timestamp
        shareCount
        likeCount
        commentCount
        reach
        impression
        engagementRate
        benchmark {
          benchmark
        }
      }
      pagination{
        next 
        hasNext
      }
    }
  }
`;

const GET_TOP_POSTMADE_PAGINATION_V2 = gql`
  query comparison_getTopPostMadeWithPaginationV2(${analyticArgumentsQuery},$columnName: SortingByColumn, $sortingMethod: SortingType, $page: Int!, $limit: Int!) {
    comparison_getTopPostMadeWithPaginationV2(${analyticArguments}, columnName: $columnName, sortingMethod: $sortingMethod, page: $page, limit: $limit) {
      result {
        originalId
        socialMedia
        displayPicture
        fromId
        fromName
        content
        viewCount
        link
        timestamp
        shareCount
        likeCount
        commentCount
        reach
        impression
        contentType
        engagementRate
        comparisonGroupName
        benchmark {
          benchmark
        }
      }
      totalRows
      totalPages
      page
      limit
    }
  }
`;

const GET_COMPARISON_TABLE_COLUMN = gql`
  query comparison_getComparisonTableColumn($userId: Id!) {
    comparison_getComparisonTableColumn(userId: $userId) {
      userId
      post
      like
      comment
      share
      reach
      engagement
      engagementRate
      followers
    }
  }
`;

const GET_ADVANCE_METRIC = gql`
query comparison_getAdvanceMetric(${analyticArgumentsQuery}) {
  comparison_getAdvanceMetric(${analyticArguments}) {
    name
    values {
      isActive
      computationName
      value
      isEligible
      isPercentage
    }
  }
}`;

export {
  GET_NAMESPACE_LIST,
  GET_COMPARISON_ENTITY,
  GET_COMPARISON_DEFAULT,
  GET_BRAND_SENTIMENT,
  GET_BRAND_POPULARITY,
  GET_BRAND_POSITIONING,
  GET_TOP_POSTMADE,
  GET_CHRONOLOGICAL_AUDIENCE_BY_BRAND,
  GET_CHRONOLOGICAL_ENGAGEMENT_BY_BRAND,
  GET_TOP_POSTMADE_PAGINATION,
  GET_BRAND_PEAK_TIME,
  GET_CHRONOLOGICAL_TALK_BY_BRAND,
  GET_COMPARISON_TABLE_COLUMN,
  GET_ADVANCE_METRIC,
  GET_TOP_POSTMADE_PAGINATION_V2
};
