import { IObject } from "src/models/objectManagement";

export const addSymbolObject = function (object: IObject) {
    if (object.streamType === "account") {
      return {
        ...object,
        objectName: `@${object.objectName}`,
      };
    } else if (object.streamType === "keyword" && !object.isHashtag) {
      return {
        ...object,
        objectName: `~${object.objectName}`,
      };
    } else {
      return object;
    }
  };