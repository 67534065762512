import { gql } from '@apollo/client';
import { analyticArguments, analyticArgumentsQuery } from '../analyticArgument';

export const UPDATE_SENTIMENT = gql`
  mutation rawStream_updateSentiment(
    $projectId: Id!
    $objectId: Id!
    $originalId: String!
    $sentiment: Sentiment!
    $postOwnership: PostOwnership!
  ) {
    rawStream_updateSentiment(
      projectId: $projectId
      objectId: $objectId
      originalId: $originalId
      sentiment: $sentiment
      postOwnership: $postOwnership
    ) {
      projectId
      objectId
      originalId
      status
      sentiment
      error
    }
  }
`;

export const MANAGE_SENTIMENT_ON_STREAM = gql`
  mutation rawStream_manageSentimentOnStreams(
    $projectId: Id!
    $objectAndStream: [ObjectAndStreamListInput!]!
    $sentiment: Sentiment!
  ) {
    rawStream_manageSentimentOnStreams(
      projectId: $projectId
      objectAndStream: $objectAndStream
      sentiment: $sentiment
    ) {
      originalId
      fromId
      fromName
      timestamp
      share
      like
      comment
      audience
      engagementRate
      sentiment
      objectModel {
        id
        socialMedia
        streamType
        content
        extraContent
        objectName
        isHashtag
        createdAt
        openApiId
        displayPicture
      }
      postOwnership
      content
      link
      label {
        id
        name
        displayName
        colorType
        isActive
        createdAt
      }
      isEdited
    }
  }
`;

export const MANAGE_SENTIMENT_ON_STREAM_BY_FILTER = gql`
  mutation rawStream_manageSentimentOnStreamsByFilter(${analyticArgumentsQuery} $updateSentiment: Sentiment!, $retweetFromOriginalIdList: [String!]) {
    rawStream_manageSentimentOnStreamsByFilter(${analyticArguments} updateSentiment: $updateSentiment, retweetFromOriginalIdList: $retweetFromOriginalIdList) {
      id
      status
    }
  }
`;

export const MANAGE_CONTENT_TYPE_ON_STREAM = gql`
  mutation rawStream_manageContentMediaTypeOnStreams(
    $projectId: Id!
    $objectAndStream: [ObjectAndStreamListInput!]!
    $contentType: ContentType!
  ) {
    rawStream_manageContentMediaTypeOnStreams(
      projectId: $projectId
      objectAndStream: $objectAndStream
      contentType: $contentType
    ) {
      originalId
      fromId
      fromName
      timestamp
      share
      like
      comment
      audience
      engagementRate
      contentType
      sentiment
      objectModel {
        id
        socialMedia
        streamType
        content
        extraContent
        objectName
        isHashtag
        createdAt
        openApiId
        displayPicture
        __typename
      }
      postOwnership
      content
      link
      label {
        id
        name
        displayName
        colorType
        isActive
        createdAt
        __typename
      }
      __typename
    }
  }
`;

export const MANAGE_CONTENT_TYPE_ON_STREAM_BY_FILTER = gql`
  mutation rawStream_manageContentMediaTypeOnStreamsByFilter(
    ${analyticArgumentsQuery}
    $updateContentMediaType: ContentType!
    $retweetFromOriginalIdList: [String!]
  ) {
    rawStream_manageContentMediaTypeOnStreamsByFilter(
      ${analyticArguments}
      updateContentMediaType: $updateContentMediaType
      retweetFromOriginalIdList: $retweetFromOriginalIdList
    ) {
      id
      status
    }
  }
`;

export const MANAGE_VISIBILITY_ON_STREAM = gql`
  mutation rawStream_manageVisibilityOnStreams(
    $projectId: Id!
    $objectAndStream: [ObjectAndStreamListInput!]!
    $visibility: Visibility!
  ) {
    rawStream_manageVisibilityOnStreams(
      projectId: $projectId
      objectAndStream: $objectAndStream
      visibility: $visibility
    ) {
      originalId
      socialMedia
      displayPicture
      fromId
      fromName
      timestamp
      share
      like
      comment
      audience
      engagementRate
      sentiment
      objectModel {
        id
        socialMedia
        streamType
        extraContent
        objectName
        isHashtag
      }
      postOwnership
      content
      link
      label {
        id
        name
        displayName
        colorType
      }
      isEdited
      streamVisibility
    }
  }
`;

export const MANAGE_VISIBILITY_BY_REQUEST = gql`
  mutation rawStream_manageVisibilityByRequest(
    $projectId: Id!
    $objectAndStream: [ObjectAndStreamListInput!]!
    $visibility: Visibility!
  ) {
    rawStream_manageVisibilityByRequest(
      projectId: $projectId
      objectAndStream: $objectAndStream
      visibility: $visibility
    ) {
      id
      streamVisibility
      status
      createdAt
    }
  }
`;

export const MANAGE_VISIBILITIES_ON_STREAM_BY_FILTER = gql`
  mutation rawStream_manageVisibilitiesOnStreamsByFilter(${analyticArgumentsQuery}, $visibility: Visibility!) {
    rawStream_manageVisibilitiesOnStreamsByFilter(${analyticArguments}, visibility: $visibility ) {
      id
      status
    }
  }
`;
