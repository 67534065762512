/* eslint-disable react-hooks/exhaustive-deps */
import {
  Autocomplete,
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Fab,
  Grid,
  OutlinedInput,
  Stack,
  styled,
  TextField,
  Typography,
  useTheme,
  IconButton,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Pagination,
  Radio
} from '@mui/material';
import { FC, useEffect, useMemo, useRef, useState } from 'react';
import { withStyles } from '@mui/styles';
import { useAppDispatch, useAppSelector } from 'src/app/hooks';
import {
  initialState,
  reducerUpdateAdvanceSearch
} from 'src/features/advanceSearchSocialMedia';
import {
  IGranularity,
  ILabel,
  IRawPostmadeOutputEntity,
  IRawPostmadeOutputWithPagination,
  TContentType,
  TStringOperator,
  TPostOwnership,
  TSentiment,
  TValidation
} from 'src/models/general';
import CheckboxCustomNl, { ChipCustom } from './CheckboxCustomNl';
import ModalSelectLabel from '../ModalSelectLabel';
import moment from 'moment';
import DeleteIcon from '@mui/icons-material/Delete';
import CloseIcon from '@mui/icons-material/Close';
import ClearIcon from '@mui/icons-material/Clear';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ContentStream from './ContentStream';
import { useDebounce } from 'src/hooks/useDebounce';
import { useQuery } from '@apollo/client';
import { fillParamAdvanceSearchEmpty } from 'src/utils/fillParamAdvanceSearchEmpth';
import { GET_CONTENT_TOP_POSTMADE_PAGINATION } from 'src/services/graphql/content/query';
import CheckIcon from '@mui/icons-material/Check';
import PanoramaFishEyeIcon from '@mui/icons-material/PanoramaFishEye';
import { RadioButtonChecked } from '@mui/icons-material';
import { correctDoubleSpaces } from 'src/utils/correctDoubleSpaces';

interface Props {
  open: boolean;
  onClose: () => void;
}

interface IOptionSentimentCheckbox {
  id: TSentiment;
  name: TSentiment;
  label: string;
  isChecked: boolean;
}

interface IOptionStreamTypeCheckbox {
  id: TPostOwnership;
  name: TPostOwnership;
  label: string;
  isChecked: boolean;
}

export interface IOptionValidationCheckbox {
  id: TValidation;
  name: TValidation;
  label: string;
  isChecked: boolean;
}

interface IOptionPostTypeCheckbox {
  id: TContentType;
  name: TContentType;
  label: string;
  isChecked: boolean;
}

type TAccordion = 'more_filters' | 'commenting_to_post' | 'confidence_score';

const CustomAutocomplete = withStyles({
  inputRoot: {
    '&[class*="MuiOutlinedInput-root"][class*="MuiOutlinedInput-marginDense"] .MuiAutocomplete-input':
      {
        padding: 0
      }
  }
})((props: any) => <Autocomplete {...props} />);

const CustomInput = withStyles((theme) => ({
  root: {
    borderRadius: '4px 0px 0px 4px',
    height: theme.spacing(5)
  }
}))(OutlinedInput);

const FabSmall = styled(Fab)(
  ({ theme }) => `
    min-height: unset;
    background-color: transparent;
    width: ${theme.spacing(3)};
    height: ${theme.spacing(3)};
    color: #E35200;
    border: 1px solid #E35200;

  `
);

const AdvanceSearchSocialMedia: FC<Props> = ({ open, onClose }) => {
  const theme = useTheme();
  const dispatch = useAppDispatch();

  const [expanded, setExpanded] = useState<TAccordion>('more_filters');

  const handleChangeAccordion =
    (panel: TAccordion) =>
    (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : 'more_filters');
    };

  const [anchorElLabel, setAnchorElLabel] = useState<HTMLDivElement | null>(
    null
  );
  const handleClickLabel = (event: any) => {
    setAnchorElLabel(event.currentTarget);
  };

  const handleCloseLabel = function () {
    setAnchorElLabel(null);
  };
  const openLabel = Boolean(anchorElLabel);

  const {
    includeKeywords,
    excludeKeywords,
    sentiment,
    fromUsername,
    postOwnership,
    validationList,
    contentTypeList,
    labelList,
    sentimentConfidenceLevelMin,
    sentimentConfidenceLevelMax,
    showEmptyLabel,
    streamsCommentingToPost,
    selectedOriginalToPost,
    excludeRt,
    queryStringBooleanOperator
  } = useAppSelector((state) => state.storeAdvanceSearchSocialMedia);

  const isValueAvailable = function <T>(values: Array<T>, valString: T) {
    const value = values.find((value: T) => value === valString);
    return value ? true : false;
  };

  const isPositiveChecked = isValueAvailable<TSentiment>(sentiment, 'positive');
  const isNeutralChecked = isValueAvailable<TSentiment>(sentiment, 'neutral');
  const isNegativeChecked = isValueAvailable<TSentiment>(sentiment, 'negative');

  const isPostmadeChecked = isValueAvailable<TPostOwnership>(
    postOwnership,
    'postmade'
  );
  const isTalkChecked = isValueAvailable<TPostOwnership>(postOwnership, 'talk');
  const isReplyChecked = isValueAvailable<TPostOwnership>(
    postOwnership,
    'reply'
  );

  const isValidationChecked = isValueAvailable<TValidation>(
    validationList,
    'validated'
  );
  const isUnValidatedChecked = isValueAvailable<TValidation>(
    validationList,
    'unvalidated'
  );

  const isContentTextChecked = isValueAvailable<TContentType>(
    contentTypeList,
    'text'
  );
  const isContentImageChecked = isValueAvailable<TContentType>(
    contentTypeList,
    'image'
  );
  const isContentVideoChecked = isValueAvailable<TContentType>(
    contentTypeList,
    'video'
  );
  const isContentOthersChecked = isValueAvailable<TContentType>(
    contentTypeList,
    'others'
  );

  const [includeKeywordsLocal, setIncludeKeywordsLocal] =
    useState<Array<string>>(includeKeywords);

  const [excludeKeywordsLocal, setExcludeKeywordsLocal] =
    useState<Array<string>>(excludeKeywords);

  const [usernameLocal, setUsernameLocal] = useState<string>(fromUsername);

  const labels = useAppSelector((state) => state.storeLabel.labelList.data);
  const initialLabels = labels.filter((label) => labelList?.includes(label.id));
  const [labelListLocal, setLabelListLocal] =
    useState<Array<ILabel>>(initialLabels);

  const [selectedOriginalToPostLocal, setSelectedOriginalToPostLocal] =
    useState<Array<IRawPostmadeOutputEntity>>(selectedOriginalToPost);

  const [
    sentimentConfidenceLevelMinLocal,
    setSentimentConfidenceLevelMinLocal
  ] = useState<number>(sentimentConfidenceLevelMin);

  const [
    sentimentConfidenceLevelMaxLocal,
    setSentimentConfidenceLevelMaxLocal
  ] = useState<number>(sentimentConfidenceLevelMax);

  const [showEmptyLabelLocal, setShowEmptyLabelLocal] =
    useState<boolean>(showEmptyLabel);

  const [excludeRtLocal, setExcludeRtLocal] = useState<boolean>(excludeRt);

  const [sentimentOptions, setSentimentOptions] = useState<
    Array<IOptionSentimentCheckbox>
  >([
    {
      id: 'positive',
      name: 'positive',
      label: 'Positive',
      isChecked: isPositiveChecked
    },
    {
      id: 'neutral',
      name: 'neutral',
      label: 'Neutral',
      isChecked: isNeutralChecked
    },
    {
      id: 'negative',
      name: 'negative',
      label: 'Negative',
      isChecked: isNegativeChecked
    }
  ]);

  const [streamTypeOptions, setStreamTypeOptions] = useState<
    Array<IOptionStreamTypeCheckbox>
  >([
    {
      id: 'postmade',
      name: 'postmade',
      label: 'Postmade',
      isChecked: isPostmadeChecked
    },
    {
      id: 'talk',
      name: 'talk',
      label: 'Talk',
      isChecked: isTalkChecked
    },
    {
      id: 'reply',
      name: 'reply',
      label: 'Reply',
      isChecked: isReplyChecked
    }
  ]);

  // const [streamValidationLabel, setValidationLabel] = useState<
  //   Array<IOptionValidationCheckbox>
  // >([
  //   {
  //     id: 'validated',
  //     name: 'validated',
  //     label: 'Validated',
  //     isChecked: isValidationChecked
  //   }
  // ]);

  const [streamValidationOptions, setValidationOptions] = useState<
    Array<IOptionValidationCheckbox>
  >([
    {
      id: 'validated',
      name: 'validated',
      label: 'Validated',
      isChecked: isValidationChecked
    },
    {
      id: 'unvalidated',
      name: 'unvalidated',
      label: 'Unvalidated',
      isChecked: isUnValidatedChecked
    }
  ]);

  const [streamContentTypeOptions, setContentTypeOptions] = useState<
    Array<IOptionPostTypeCheckbox>
  >([
    {
      id: 'text',
      name: 'text',
      label: 'Text',
      isChecked: isContentTextChecked
    },
    {
      id: 'image',
      name: 'image',
      label: 'Image',
      isChecked: isContentImageChecked
    },
    {
      id: 'video',
      name: 'video',
      label: 'Video',
      isChecked: isContentVideoChecked
    },
    {
      id: 'others',
      name: 'others',
      label: 'Others',
      isChecked: isContentOthersChecked
    }
  ]);

  const [streams, setStreams] = useState<IRawPostmadeOutputWithPagination>(
    streamsCommentingToPost
  );

  const [queryStringBooleanOperatorLocal, setQueryStringBooleanOperatorLocal] =
    useState<TStringOperator>(queryStringBooleanOperator);

  const handleChangeKeywordMode = (event: any) => {
    setQueryStringBooleanOperatorLocal(event.target.value);
  };

  const handleSearchIncludes = (value: string[]) => {
    const removedDobleSpaceStrings = value.map((text) =>
      correctDoubleSpaces(text)
    );
    setIncludeKeywordsLocal(removedDobleSpaceStrings);
  };

  const handleSearchExcludes = (value: string[]) => {
    const removedDobleSpaceStrings = value.map((text) =>
      correctDoubleSpaces(text)
    );
    setExcludeKeywordsLocal(removedDobleSpaceStrings);
  };

  const handleChangeOption = (prev, optionId) =>
    prev.map((option) => {
      if (option.id === optionId)
        return { ...option, isChecked: !option.isChecked };
      return option;
    });

  const handleClickSentiment = (optionId: TSentiment) => {
    setSentimentOptions((prev) => handleChangeOption(prev, optionId));
  };

  const handleClickStreamType = (optionId: TPostOwnership) => {
    setStreamTypeOptions((prev) => handleChangeOption(prev, optionId));
  };

  const handleClickValidation = (optionId: TValidation) => {
    setValidationOptions((prev) => handleChangeOption(prev, optionId));
  };

  const handleClickContentType = (optionId: TContentType) => {
    setContentTypeOptions((prev) => handleChangeOption(prev, optionId));
  };

  const handleChangeConfidenceScoreMin = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    event.persist();
    let value = parseFloat(event.target.value);
    if (value > 1) value = 1;
    if (value < 0) value = 0;
    setSentimentConfidenceLevelMinLocal(value);
  };

  const handleChangeConfidenceScoreMax = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    event.persist();
    let value = parseFloat(event.target.value);
    if (value > 1) value = 1;
    if (value < 0) value = 0;
    setSentimentConfidenceLevelMaxLocal(value);
  };

  const handleDiscard = () => {
    setIncludeKeywordsLocal([]);
    setExcludeKeywordsLocal([]);
    setUsernameLocal('');
    setLabelListLocal([]);
    setSentimentConfidenceLevelMinLocal(0);
    setSentimentConfidenceLevelMaxLocal(1);
    setSelectedOriginalToPostLocal([]);
    setStreams({
      result: [],
      totalRows: 0,
      totalPages: 0,
      page: 1,
      limit: 20
    });
    setSentimentOptions([
      {
        id: 'positive',
        name: 'positive',
        label: 'Positive',
        isChecked: false
      },
      {
        id: 'neutral',
        name: 'neutral',
        label: 'Neutral',
        isChecked: false
      },
      {
        id: 'negative',
        name: 'negative',
        label: 'Negative',
        isChecked: false
      }
    ]);
    setStreamTypeOptions([
      {
        id: 'postmade',
        name: 'postmade',
        label: 'Postmade',
        isChecked: false
      },
      {
        id: 'talk',
        name: 'talk',
        label: 'Talk',
        isChecked: false
      },
      {
        id: 'reply',
        name: 'reply',
        label: 'Reply',
        isChecked: false
      }
    ]);
    setValidationOptions([
      {
        id: 'validated',
        name: 'validated',
        label: 'Validated',
        isChecked: false
      },
      {
        id: 'unvalidated',
        name: 'unvalidated',
        label: 'Unvalidated',
        isChecked: false
      }
    ]);
    setContentTypeOptions([
      {
        id: 'text',
        name: 'text',
        label: 'Text',
        isChecked: false
      },
      {
        id: 'image',
        name: 'image',
        label: 'Image',
        isChecked: false
      },
      {
        id: 'video',
        name: 'video',
        label: 'Video',
        isChecked: false
      },
      {
        id: 'others',
        name: 'others',
        label: 'Others',
        isChecked: false
      }
    ]);
    setQueryStringBooleanOperatorLocal('and');
  };

  // for date range label
  const dateRangeOptionActive = useAppSelector(
    (state) => state.analyticArguments.dateRangeOptionActive
  );

  const dateRangeLabel = () => {
    if (dateRangeOptionActive.id !== 'custom') {
      return dateRangeOptionActive.label;
    }
    return `${moment(dateRangeOptionActive.start).format('L HH:mm')} - ${moment(
      dateRangeOptionActive.end
    ).format('L HH:mm')}`;
  };

  // for date range label

  const handleDone = () => {
    // const sentiments = sentimentOptions
    //   .filter((sentiment) => sentiment.isChecked)
    //   .map((sen) => sen.id);
    dispatch(
      reducerUpdateAdvanceSearch({
        includeKeywords: includeKeywordsLocal,
        excludeKeywords: excludeKeywordsLocal,
        fromUsername: usernameLocal,
        sentiment: sentimentOptions
          .filter((sentiment) => sentiment.isChecked)
          .map((sentiment) => sentiment.id),
        postOwnership: streamTypeOptions
          .filter((type) => type.isChecked)
          .map((type) => type.id),
        contentTypeList: streamContentTypeOptions
          .filter((type) => type.isChecked)
          .map((type) => type.id),
        validationList: streamValidationOptions
          .filter((type) => type.isChecked)
          .map((type) => type.id),
        labelList: labelListLocal.map((label) => label.id),
        sentimentConfidenceLevelMin: sentimentConfidenceLevelMinLocal,
        sentimentConfidenceLevelMax: sentimentConfidenceLevelMaxLocal,
        showEmptyLabel: showEmptyLabelLocal,
        replyToOriginalId: selectedOriginalToPostLocal.map(
          (stream) => stream.originalId
        ),
        streamsCommentingToPost: streams,
        selectedOriginalToPost: selectedOriginalToPostLocal,
        searchOriginalToPost: debouncedState,
        excludeRt: excludeRtLocal,
        queryStringBooleanOperator: queryStringBooleanOperatorLocal
      })
    );
    onClose();
  };

  const [debouncedState, setDebouncedState] = useDebounce('');
  const [searchFormValue, setSearchFormValue] = useState('');

  const handleChangeSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.persist();
    const keyword = event.target.value;
    setSearchFormValue(keyword);
    setDebouncedState(() => keyword);
  };
  const handleDeleteSearch = () => {
    setSearchFormValue('');
    setDebouncedState(() => '');
  };
  const handleClearSelection = () => {
    setSelectedOriginalToPostLocal([]);
  };

  const projectId = useAppSelector((state) => state.users.project.id);
  const { dateRangeFormatted, objectIdList } = useAppSelector(
    (state) => state.analyticArguments
  );

  const granularity: IGranularity = {
    unit: 'day',
    value: 1
  };

  const isOriginalPostSelected = (id: string) =>
    selectedOriginalToPostLocal.findIndex((obj) => obj.originalId === id) !==
    -1;

  const handleClickOriginalPost = function (label: IRawPostmadeOutputEntity) {
    const selectedIndex = selectedOriginalToPostLocal.findIndex(
      (obj) => obj.originalId === label.originalId
    );
    let newSelected: IRawPostmadeOutputEntity[] = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedOriginalToPostLocal, label);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedOriginalToPostLocal.slice(1));
    } else if (selectedIndex === selectedOriginalToPostLocal.length - 1) {
      newSelected = newSelected.concat(
        selectedOriginalToPostLocal.slice(0, -1)
      );
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedOriginalToPostLocal.slice(0, selectedIndex),
        selectedOriginalToPostLocal.slice(selectedIndex + 1)
      );
    }
    setSelectedOriginalToPostLocal(newSelected);
  };

  const fillAdvanceSearchSocialMedia = useMemo(() => {
    return fillParamAdvanceSearchEmpty(initialState);
  }, [initialState]);

  const finalPayload = {
    ...fillAdvanceSearchSocialMedia,
    projectId,
    ...dateRangeFormatted,
    objectList: objectIdList,
    granularity,
    timezone: 7
  };

  const [page, setPage] = useState(1);

  const {
    refetch: refetchContentTopPostmade,
    loading: contentTopPostmadeLoading
  } = useQuery(GET_CONTENT_TOP_POSTMADE_PAGINATION, {
    variables: {
      ...finalPayload,
      limit: 20,
      page
    },
    onCompleted: ({ content_getTopPostMadeWithPaginationV2 }) => {
      setStreams(content_getTopPostMadeWithPaginationV2);
    }
  });

  useEffect(() => {
    const keywords = debouncedState === '' ? [] : [debouncedState];
    refetchContentTopPostmade({
      ...finalPayload,
      includeKeywords: keywords,
      limit: 20,
      page: 1
    });
  }, [debouncedState]);

  useEffect(() => {
    const keywords = debouncedState === '' ? [] : [debouncedState];
    refetchContentTopPostmade({
      ...finalPayload,
      includeKeywords: keywords,
      limit: 20,
      page
    });
  }, [page]);

  useEffect(() => {
    setIncludeKeywordsLocal(includeKeywords);
  }, [includeKeywords]);

  useEffect(() => {
    setUsernameLocal(fromUsername);
  }, [fromUsername]);

  const tfStyle = {
    '& .MuiButtonBase-root.MuiAutocomplete-clearIndicator': {
      color: 'blue',
      visibility: 'visible'
    }
  };

  const keywordRef = useRef(null);

  return (
    <>
      <Dialog onClose={onClose} open={open} fullWidth maxWidth={'md'}>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500]
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogTitle
          sx={{
            m: theme.spacing(3, 3, 0),
            p: theme.spacing(2, 2, 0, 2)
          }}
        >
          <Typography
            variant="h3"
            sx={{
              fontWeight: 600,
              fontSize: 20,
              textAlign: 'left'
            }}
          >
            Advance Search
          </Typography>
        </DialogTitle>
        <DialogContent
          sx={{
            m: theme.spacing(1, 2)
          }}
        >
          <Accordion
            expanded={expanded === 'more_filters'}
            onChange={handleChangeAccordion('more_filters')}
          >
            {expanded === 'more_filters' ? null : (
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography sx={{ width: '95%', flexShrink: 0 }}>
                  More Filters
                </Typography>
                <Typography
                  sx={{
                    fontSize: 14,
                    color: 'text.secondary'
                  }}
                >
                  Show
                </Typography>
              </AccordionSummary>
            )}
            <AccordionDetails>
              <Grid container spacing={2}>
                <Grid item lg={12}>
                  <Typography sx={{ mb: theme.spacing(0.5) }} variant="h5">
                    Includes
                  </Typography>
                  <CustomAutocomplete
                    multiple
                    id="keyword-includes"
                    size="small"
                    options={[]}
                    defaultValue={[]}
                    freeSolo
                    ref={keywordRef}
                    value={includeKeywordsLocal}
                    renderTags={(values: Array<any>, getTagProps) =>
                      values.map((option: string, index: number) => (
                        <Chip
                          key={`${option}-${index}-includes`}
                          variant="outlined"
                          label={<Typography>{option}</Typography>}
                          size="small"
                          {...getTagProps({ index })}
                        />
                      ))
                    }
                    onChange={(_e, value) => {
                      handleSearchIncludes(value);
                    }}
                    forcePopupIcon={false}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        name="includes"
                        variant="outlined"
                        size="small"
                        autoComplete="off"
                        placeholder="Separated by Enter"
                        sx={{ tfStyle }}
                        onPaste={(event) => {
                          const keywords = event.clipboardData.getData('text');
                          const splitKeyword = keywords.split(',');
                          const trimAndFilterKeyword = splitKeyword
                            .filter((word) => word.trim().length > 1)
                            .map((word) => word.trim());

                          const value = [
                            ...includeKeywordsLocal,
                            ...trimAndFilterKeyword
                          ];
                          handleSearchIncludes(value);
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item lg={12}>
                  <Typography sx={{ mb: theme.spacing(0.5) }} variant="h5">
                    Excludes
                  </Typography>
                  <CustomAutocomplete
                    multiple
                    id="keyword-includes"
                    size="small"
                    options={[]}
                    defaultValue={[]}
                    freeSolo
                    value={excludeKeywordsLocal}
                    renderTags={(values: Array<any>, getTagProps) =>
                      values.map((option: string, index: number) => (
                        <Chip
                          key={`${option}-${index}-includes`}
                          variant="outlined"
                          label={<Typography>{option}</Typography>}
                          size="small"
                          {...getTagProps({ index })}
                        />
                      ))
                    }
                    onChange={(_e, value) => {
                      handleSearchExcludes(value);
                    }}
                    forcePopupIcon={false}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        name="includes"
                        variant="outlined"
                        size="small"
                        autoComplete="off"
                        placeholder="Separated by Enter"
                        onPaste={(event) => {
                          const keywords = event.clipboardData.getData('text');
                          const splitKeyword = keywords.split(',');
                          const trimAndFilterKeyword = splitKeyword
                            .filter((word) => word.trim().length > 1)
                            .map((word) => word.trim());
                          const value = [
                            ...excludeKeywordsLocal,
                            ...trimAndFilterKeyword
                          ];
                          handleSearchExcludes(value);
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item lg={12}>
                  <Typography
                    id="keywordMode"
                    sx={{ mb: theme.spacing(0.5) }}
                    variant="h5"
                  >
                    Mode for Includes & Excludes
                  </Typography>
                  <Stack direction="row" gap={2}>
                    <Radio
                      checked={queryStringBooleanOperatorLocal === 'and'}
                      value="and"
                      size="small"
                      inputProps={{ 'aria-label': 'And' }}
                      onChange={handleChangeKeywordMode}
                      checkedIcon={
                        <ChipCustom
                          variant="filled"
                          deleteIcon={<CheckIcon />}
                          label={
                            <Stack direction="row" alignItems="center">
                              <RadioButtonChecked
                                sx={{
                                  mr: 1,
                                  width: 16,
                                  height: 16,
                                  color: theme.palette.primary.main
                                }}
                              />{' '}
                              <Typography
                                variant="subtitle2"
                                sx={{
                                  color: theme.palette.primary.main,
                                  fontSize: 13,
                                  fontWeight: 'normal'
                                }}
                              >
                                And
                              </Typography>
                            </Stack>
                          }
                          size="small"
                          sx={{
                            background: theme.palette.primary.light,
                            padding: '10px 56px 10px 8px'
                          }}
                          colorChip={theme.palette.common.black}
                        />
                      }
                      icon={
                        <ChipCustom
                          variant="filled"
                          deleteIcon={<CheckIcon />}
                          label={
                            <Stack direction="row" alignItems="center">
                              <PanoramaFishEyeIcon
                                sx={{
                                  mr: 1,
                                  width: 16,
                                  height: 16,
                                  color: theme.palette.primary.main
                                }}
                              />{' '}
                              <Typography
                                variant="subtitle2"
                                sx={{
                                  color: theme.palette.primary.main,
                                  fontSize: 13,
                                  fontWeight: 'normal'
                                }}
                              >
                                And
                              </Typography>
                            </Stack>
                          }
                          size="small"
                          sx={{
                            background: theme.palette.primary.light,
                            padding: '10px 56px 10px 8px'
                          }}
                          colorChip={theme.palette.common.black}
                        />
                      }
                    />
                    <Radio
                      checked={queryStringBooleanOperatorLocal === 'or'}
                      value="or"
                      size="small"
                      inputProps={{ 'aria-label': 'Or' }}
                      onChange={handleChangeKeywordMode}
                      checkedIcon={
                        <ChipCustom
                          variant="filled"
                          deleteIcon={<CheckIcon />}
                          label={
                            <Stack direction="row" alignItems="center">
                              <RadioButtonChecked
                                sx={{
                                  mr: 1,
                                  width: 16,
                                  height: 16,
                                  color: theme.palette.primary.main
                                }}
                              />{' '}
                              <Typography
                                variant="subtitle2"
                                sx={{
                                  color: theme.palette.primary.main,
                                  fontSize: 13,
                                  fontWeight: 'normal'
                                }}
                              >
                                Or
                              </Typography>
                            </Stack>
                          }
                          size="small"
                          sx={{
                            background: theme.palette.primary.light,
                            padding: '10px 56px 10px 8px'
                          }}
                          colorChip={theme.palette.common.black}
                        />
                      }
                      icon={
                        <ChipCustom
                          variant="filled"
                          deleteIcon={<CheckIcon />}
                          label={
                            <Stack direction="row" alignItems="center">
                              <PanoramaFishEyeIcon
                                sx={{
                                  mr: 1,
                                  width: 16,
                                  height: 16,
                                  color: theme.palette.primary.main
                                }}
                              />{' '}
                              <Typography
                                variant="subtitle2"
                                sx={{
                                  color: theme.palette.primary.main,
                                  fontSize: 13,
                                  fontWeight: 'normal'
                                }}
                              >
                                Or
                              </Typography>
                            </Stack>
                          }
                          size="small"
                          sx={{
                            background: theme.palette.primary.light,
                            padding: '10px 56px 10px 8px'
                          }}
                          colorChip={theme.palette.common.black}
                        />
                      }
                    />
                  </Stack>
                </Grid>
                <Grid item lg={12}>
                  <Typography sx={{ mb: theme.spacing(0.5) }} variant="h5">
                    Username
                  </Typography>
                  <TextField
                    size="small"
                    fullWidth
                    placeholder="@example"
                    onChange={(e) => setUsernameLocal(e.target.value)}
                    value={usernameLocal}
                  />
                </Grid>
                <Grid item lg={12}>
                  <Typography sx={{ mb: theme.spacing(0.5) }} variant="h5">
                    Sentiment
                  </Typography>
                  <Stack direction="row" alignItems="center">
                    {sentimentOptions.map((option) => (
                      <CheckboxCustomNl
                        {...option}
                        key={option.id}
                        onClick={handleClickSentiment}
                      />
                    ))}
                  </Stack>
                </Grid>
                <Grid item lg={12}>
                  <Typography sx={{ mb: theme.spacing(0.5) }} variant="h5">
                    Stream Type
                  </Typography>
                  <Stack direction="row" alignItems="center">
                    {streamTypeOptions.map((option) => (
                      <CheckboxCustomNl
                        {...option}
                        key={option.id}
                        onClick={handleClickStreamType}
                      />
                    ))}
                  </Stack>
                </Grid>
                <Grid item lg={12}>
                  <Typography sx={{ mb: theme.spacing(0.5) }} variant="h5">
                    Validation
                  </Typography>
                  <Stack direction="row" alignItems="center">
                    {streamValidationOptions.map((option) => (
                      <CheckboxCustomNl
                        {...option}
                        key={option.id}
                        onClick={handleClickValidation}
                      />
                    ))}
                  </Stack>
                </Grid>
                <Grid item lg={12}>
                  <Typography sx={{ mb: theme.spacing(0.5) }} variant="h5">
                    Post Type
                  </Typography>
                  <Stack direction="row" alignItems="center">
                    {streamContentTypeOptions.map((option) => (
                      <CheckboxCustomNl
                        {...option}
                        key={option.id}
                        onClick={handleClickContentType}
                      />
                    ))}
                  </Stack>
                </Grid>
                <Grid item lg={12}>
                  <Typography sx={{ mb: theme.spacing(0.5) }} variant="h5">
                    Retweet
                  </Typography>
                  <Stack direction="row" alignItems={'flex-start'}>
                    <CheckboxCustomNl
                      id="excludeRt"
                      label="Exclude RT in all social media Twitter"
                      name="setExcludeRT"
                      isChecked={excludeRtLocal}
                      onClick={() => setExcludeRtLocal(!excludeRtLocal)}
                    />
                  </Stack>
                </Grid>
                <Grid item lg={12}>
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    sx={{ mb: theme.spacing(1) }}
                  >
                    <Typography variant="h5">Label</Typography>
                    {labelListLocal.length > 0 && (
                      <Button
                        size="small"
                        variant="outlined"
                        sx={{ maxHeight: theme.spacing(3.5) }}
                        onClick={handleClickLabel}
                      >
                        Select +
                      </Button>
                    )}
                  </Stack>
                  <Box
                    sx={{
                      background: '#FBFBFB',
                      borderRadius: theme.spacing(1),
                      maxHeight: theme.spacing(9 * 4)
                    }}
                  >
                    {labelListLocal.length < 1 && (
                      <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        <CheckboxCustomNl
                          id="unlabeled"
                          label="Unlabeled"
                          name="setShowEmptyLabelLocal"
                          isChecked={showEmptyLabelLocal}
                          onClick={() =>
                            setShowEmptyLabelLocal(!showEmptyLabelLocal)
                          }
                        />
                        <Button
                          variant="outlined"
                          size="small"
                          onClick={handleClickLabel}
                          sx={{ margin: theme.spacing(2, 1.5) }}
                        >
                          Select Label
                        </Button>
                      </Stack>
                    )}
                    <Box
                      sx={{
                        maxHeight: theme.spacing(25),
                        overflow: 'auto'
                      }}
                    >
                      {labelListLocal.length > 0 &&
                        labelListLocal.map((label) => (
                          <Box
                            sx={{
                              padding: theme.spacing(1, 2)
                            }}
                          >
                            <Stack
                              direction="row"
                              alignItems="center"
                              justifyContent="space-between"
                              spacing={1}
                            >
                              <Box sx={{ maxWidth: '70%' }}>
                                <Typography>{label.displayName}</Typography>
                              </Box>
                              <Stack
                                direction="row"
                                alignItems="center"
                                spacing={3}
                              >
                                <Stack direction={'row'}>
                                  <Typography
                                    variant="subtitle2"
                                    sx={{
                                      fontWeight: 500,
                                      fontSize: 13
                                    }}
                                  >
                                    {moment(label.createdAt).format(
                                      'ddd, MMM DD YYYY, HH:mm'
                                    )}
                                  </Typography>
                                </Stack>
                                <FabSmall
                                  size="small"
                                  sx={{
                                    marginLeft: theme.spacing(2)
                                  }}
                                >
                                  <DeleteIcon
                                    sx={{
                                      fontSize: theme.spacing(1.5)
                                    }}
                                    onClick={() =>
                                      setLabelListLocal((prev) =>
                                        prev.filter(
                                          (prevLabel) =>
                                            prevLabel.id !== label.id
                                        )
                                      )
                                    }
                                  />
                                </FabSmall>
                              </Stack>
                            </Stack>
                          </Box>
                        ))}
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === 'commenting_to_post'}
            onChange={handleChangeAccordion('commenting_to_post')}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography sx={{ width: '95%', flexShrink: 0 }}>
                Commenting to Post
              </Typography>
              <Typography
                sx={{
                  fontSize: 14,
                  color: 'text.secondary'
                }}
              >
                {expanded === 'commenting_to_post' ? 'Hide' : 'Show'}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={2}>
                <Grid item lg={12}>
                  <Box
                    sx={{
                      background: theme.colors.primary.main,
                      borderRadius: '12px 12px 0px 0px',
                      padding: theme.spacing(1.2)
                    }}
                  >
                    <Stack
                      direction="row"
                      alignItems="center"
                      justifyContent="center"
                      spacing={8}
                    >
                      <Typography sx={{ color: '#FBFBFB' }}>
                        {`Viewing post within ${dateRangeLabel()}`}
                      </Typography>
                      <TextField
                        id="search"
                        variant="outlined"
                        placeholder="Search"
                        size="small"
                        sx={{
                          width: '30%',
                          backgroundColor: '#ffffff',
                          borderRadius: '8px',
                          cursor: 'pointer'
                        }}
                        value={searchFormValue}
                        onChange={handleChangeSearch}
                        InputProps={{
                          endAdornment: debouncedState ? (
                            <IconButton
                              size="small"
                              onClick={handleDeleteSearch}
                            >
                              <ClearIcon />
                            </IconButton>
                          ) : undefined
                        }}
                      />
                      <Button
                        variant="contained"
                        sx={{ textDecoration: 'underline' }}
                        onClick={handleClearSelection}
                      >
                        Clear selection
                      </Button>
                    </Stack>
                  </Box>
                  <Box
                    sx={{
                      maxHeight: theme.spacing(40),
                      borderRadius: '0 0 12px 12px',
                      background: '#F5F5F5'
                    }}
                  >
                    {contentTopPostmadeLoading ? (
                      <Box
                        justifyContent="center"
                        display="flex"
                        alignItems="center"
                        sx={{
                          height: theme.spacing(40)
                        }}
                      >
                        <CircularProgress size={52} />
                      </Box>
                    ) : (
                      <Box
                        sx={{
                          maxHeight: theme.spacing(40),
                          background: '#ffffff',
                          overflow: 'auto',
                          border: '1px solid #F5F5F5',
                          borderRadius: '0 0 12px 12px',
                          padding: theme.spacing(1)
                        }}
                      >
                        {streams.result.map((stream) => (
                          <FormControlLabel
                            key={stream.originalId}
                            label={
                              <ContentStream
                                originalId={stream.originalId}
                                account={stream.fromName}
                                link={stream.link}
                                image={stream.displayPicture}
                                status={stream.content}
                                socialMedia={stream.socialMedia}
                              />
                            }
                            control={
                              <Checkbox
                                onChange={() => {
                                  handleClickOriginalPost(stream);
                                }}
                                checked={isOriginalPostSelected(
                                  stream.originalId
                                )}
                              />
                            }
                          />
                        ))}
                      </Box>
                    )}
                  </Box>
                </Grid>
                <Pagination
                  count={streams.totalPages}
                  sx={{ mx: 'auto' }}
                  shape="rounded"
                  color="primary"
                  size="large"
                  page={streams.page}
                  onChange={(_, page) => {
                    setPage(page);
                  }}
                  disabled={contentTopPostmadeLoading}
                />
              </Grid>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === 'confidence_score'}
            onChange={handleChangeAccordion('confidence_score')}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography sx={{ width: '95%', flexShrink: 0 }}>
                Confidence Score
              </Typography>
              <Typography
                sx={{
                  fontSize: 14,
                  color: 'text.secondary'
                }}
              >
                {expanded === 'confidence_score' ? 'Hide' : 'Show'}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid item lg={12}>
                <Grid container spacing={3}>
                  <Grid item lg={6}>
                    <Typography sx={{ mb: theme.spacing(0.5) }} variant="h5">
                      From
                    </Typography>
                    <CustomInput
                      value={sentimentConfidenceLevelMinLocal}
                      fullWidth
                      onChange={handleChangeConfidenceScoreMin}
                      type="number"
                      inputProps={{ min: 0, max: 1, step: '0.1' }}
                    />
                  </Grid>
                  <Grid item lg={6}>
                    <Typography sx={{ mb: theme.spacing(0.5) }} variant="h5">
                      To
                    </Typography>
                    <CustomInput
                      value={sentimentConfidenceLevelMaxLocal}
                      fullWidth
                      onChange={handleChangeConfidenceScoreMax}
                      type="number"
                      inputProps={{ min: 0, max: 1, step: '0.1' }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </DialogContent>
        <DialogActions>
          <Stack
            spacing={2}
            justifyContent="center"
            display="flex"
            direction="row"
            sx={{ mt: theme.spacing(3) }}
          >
            <Button
              variant="outlined"
              sx={{
                width: theme.spacing(20)
              }}
              onClick={handleDiscard}
              size="medium"
            >
              Discard
            </Button>
            <Button
              variant="contained"
              sx={{
                width: theme.spacing(20)
              }}
              onClick={handleDone}
              size="medium"
            >
              Done
            </Button>
          </Stack>
        </DialogActions>
      </Dialog>
      <ModalSelectLabel
        open={openLabel}
        onClose={handleCloseLabel}
        onSubmit={(label) => {
          handleCloseLabel();
          setLabelListLocal(label);
        }}
        prevSelectedLabels={labelListLocal}
      />
    </>
  );
};

export default AdvanceSearchSocialMedia;
