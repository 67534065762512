import gql from 'graphql-tag';
import {
  analyticArgumentsOnlineMedia,
  analyticArgumentsQueryOnlineMedia
} from '../analyticArgument';

export const ADD_ONLINE_MEDIA_CLIPPING = gql`
  mutation onlineMedia_addClipping(
    $projectId: Id!
    $name: String!
    $phrases: [String!]!
    $startingDate: Timestamp!
  ) {
    onlineMedia_addClipping(
      projectId: $projectId
      name: $name
      phrases: $phrases
      startingDate: $startingDate
    ) {
      id
      name
      createdAt
      phrases
      startingDate
      __typename
    }
  }
`;
export const DELETE_ONLINE_MEDIA_CLIPPING = gql`
  mutation onlineMedia_deleteClipping($clippingId: Id!) {
    onlineMedia_deleteClipping(clippingId: $clippingId) {
      id
      name
      createdAt
      phrases
    }
  }
`;
export const EDIT_ONLINE_MEDIA_CLIPPING = gql`
  mutation onlineMedia_editClipping(
    $clippingId: Id!
    $name: String!
    $phrases: [String!]!
    $startingDate: Timestamp!
  ) {
    onlineMedia_editClipping(
      clippingId: $clippingId
      name: $name
      phrases: $phrases
      startingDate: $startingDate
    ) {
      id
      name
      createdAt
      phrases
    }
  }
`;

export const article = `
  originalId
  createdAt
  datePublished
  displayPicture
  title
  content
  link
  people
  place
  quote
  sentiment
  sourceName
  updatedAt
  writer
  prValues
  isEdited  
  clippingList{
    id
    name
    isActive
    createdAt
  }
  labelArticleList{
    id
    name
    displayName
    colorType
    isActive
    createdAt
    clippingId
  }
  listOfClippingId`;

export const MANAGE_LABELS_ON_STREAM = gql`
  mutation labellingSystem_manageLabelsOnStreams(
    $projectId: Id!
    $objectAndStream: [ObjectAndStreamListInput!]!
    $attachLabels: [Id!]!
    $removeLabels: [Id!]!
  ) {
    labellingSystem_manageLabelsOnStreams(
      projectId: $projectId
      objectAndStream: $objectAndStream
      attachLabels: $attachLabels
      removeLabels: $removeLabels
    ) {
      originalId
      socialMedia
      displayPicture
      link
      content
      fromId
      fromName
      timestamp
      share
      comment
      like
      audience
      engagementRate
      sentiment
      objectModel {
        id
        socialMedia
        streamType
        content
        extraContent
        objectName
        isHashtag
        createdAt
        openApiId
      }
      postOwnership
      label {
        id
        name
        displayName
        colorType
        isActive
        createdAt
      }
    }
  }
`;

export const CREATE_LABEL = gql`
  mutation labellingSystem_createLabel(
    $projectId: Id!
    $name: String!
    $requestColor: String!
  ) {
    labellingSystem_createLabel(
      projectId: $projectId
      name: $name
      requestColor: $requestColor
    ) {
      id
      name
      displayName
      colorType
      isActive
      createdAt
    }
  }
`;

export const MANAGE_LABELS_ON_STREAM_BY_FILTER = gql`
  mutation labellingSystem_manageLabelsOnStreamsByFilter(${analyticArgumentsQueryOnlineMedia}) {
    labellingSystem_manageLabelsOnStreamsByFilter(${analyticArgumentsOnlineMedia}) {
      error
      executionTime
      updatedCount
    }
  }
`;

export const DELETE_LABEL = gql`
  mutation labellingSystem_deleteLabel($projectId: Id!, $name: String!) {
    labellingSystem_deleteLabel(projectId: $projectId, name: $name) {
      id
      name
      displayName
      colorType
      isActive
      createdAt
    }
  }
`;

export const ADD_CLIPPING = gql`
  mutation onlineMedia_addClipping($projectId: Id!, $name: String!) {
    onlineMedia_addClipping(projectId: $projectId, name: $name) {
      id
      name
      createdAt
    }
  }
`;

export const EDIT_CLIPPING = gql`
  mutation onlineMedia_editClipping($clippingId: Id!, $name: String!) {
    onlineMedia_editClipping(clippingId: $clippingId, name: $name) {
      id
      name
      createdAt
    }
  }
`;

export const DELETE_CLIPPING = gql`
  mutation onlineMedia_deleteClipping($clippingId: Id!) {
    onlineMedia_deleteClipping(clippingId: $clippingId) {
      id
      name
      createdAt
    }
  }
`;
export const ADD_AUTO_CLIPPING = gql`
  mutation onlineMedia_addAutoClipping(
    $projectId: Id!
    $name: String!
    $clippingIds: [Id!]!
    $phrases: [String!]!
    $startDate: Timestamp!
  ) {
    onlineMedia_addAutoClipping(
      projectId: $projectId
      name: $name
      clippingIds: $clippingIds
      phrases: $phrases
      startDate: $startDate
    ) {
      id
      name
      clippings {
        id
        name
        createdAt
      }
      phrases
      startDate
      createdAt
    }
  }
`;

export const EDIT_AUTO_CLIPPING = gql`
  mutation onlineMedia_editAutoClipping(
    $autoClippingId: Id!
    $name: String!
    $clippingIds: [Id!]!
    $phrases: [String!]!
    $startDate: Timestamp!
  ) {
    onlineMedia_editAutoClipping(
      autoClippingId: $autoClippingId
      name: $name
      clippingIds: $clippingIds
      phrases: $phrases
      startDate: $startDate
    ) {
      id
      name
      clippings {
        id
        name
        createdAt
      }
      phrases
      startDate
      createdAt
    }
  }
`;

export const DELETE_AUTO_CLIPPING = gql`
  mutation onlineMedia_deleteAutoClipping($autoClippingId: Id!) {
    onlineMedia_deleteAutoClipping(autoClippingId: $autoClippingId) {
      id
      name
      clippings {
        id
        name
        createdAt
      }
      phrases
      startDate
      createdAt
    }
  }
`;

export const RE_AUTO_CLIPPING = gql`
  mutation onlineMedia_reAutoClipping(
    $autoClippingId: Id!
    $startDate: Timestamp!
  ) {
    onlineMedia_reAutoClipping(
      autoClippingId: $autoClippingId
      startDate: $startDate
    ) {
      id
      name
      clippings {
        id
        name
        createdAt
      }
      phrases
      startDate
      createdAt
    }
  }
`;

export const ADD_PHRASE_TO_CLIPPING = gql`
  mutation onlineMedia_addPhraseToClipping(
    $projectId: Id!
    $phrases: [String!]
    $attachClippings: [Id!]!
  ) {
    onlineMedia_addPhraseToClipping(
      projectId: $projectId
      phrases: $phrases
      attachClippings: $attachClippings
    ) {
      projectId
      phrases
      clippingList {
        id
        name
        isActive
        createdAt
      }
    }
  }
`;

export const MANAGE_ARTICLES_ON_CLIPPING = gql`
  mutation onlineMedia_manageArticlesOnClipping(
    $projectId: Id!
    $clippingId: Id!
    $attachArticles: [String!]!
    $removeArticles: [String!]!
  ) {
    onlineMedia_manageArticlesOnClipping(
      projectId: $projectId
      clippingId: $clippingId 
      attachArticles: $attachArticles
      removeArticles: $removeArticles
    ) {
      projectId
      manageResponse {
        remove{
          ${article}
        }
        attach{
          ${article}
        }
      }
    }
  }
`;

export const MANAGE_CLIPPING_ON_ARTICLE = gql`
  mutation onlineMedia_manageClippingOnArticles(
    $projectId: Id!
    $articlesId: [String!]!
    $attachClippings: [Id!]!
    $removeClippings: [Id!]!
  ) {
    onlineMedia_manageClippingOnArticles(
      projectId: $projectId
      articlesId: $articlesId 
      attachClippings: $attachClippings
      removeClippings: $removeClippings
    ) {
      projectId
      attachArticle {
        ${article}
      }
    }
  }
`;

export const MANAGE_SENTIMENT_ON_ARTICLE = gql`
  mutation onlineMedia_manageSentimentOnArticle(
    $projectId: Id!
    $clippingId: Id!
    $attachArticles: [String!]!
    $sentiment: Sentiment
  ) {
    onlineMedia_manageSentimentOnArticle(
      projectId: $projectId
      clippingId: $clippingId 
      attachArticles: $attachArticles
      sentiment: $sentiment
    ) {
      projectId
      attachArticles {
        ${article}
      }
      sentiment
      clipping{
        id
        name
        isActive
        createdAt
      }
    }
  }
`;

export const MANAGE_SENTIMENT_ON_ARTICLE_BY_FILTER = gql`
  mutation onlineMedia_manageSentimentOnArticleByFilter(
    $projectId: Id!
    $start: Timestamp!
    $end: Timestamp!
    $mediaList: [String!]!
    $granularity: GranularityInput!
    $timezone: Int!
    $phrases: [String!]
    $sentiment: [Sentiment!]
    $postOwnership: [PostOwnership!]
    $labelList: [Id!]
    $clippingId: Id
    $sentimentUpdate: Sentiment
    $includeKeywords: [String!]
    $excludeKeywords: [String!]
    $validationList: [ValidationType!]
    $searchType: SearchType
    $languageList: [String!]
  ) {
    onlineMedia_manageSentimentOnArticleByFilter(
      projectId: $projectId
      start: $start
      end: $end
      mediaList: $mediaList
      granularity: $granularity
      timezone: $timezone
      phrases: $phrases
      sentiment: $sentiment
      postOwnership: $postOwnership
      labelList: $labelList
      clippingId: $clippingId
      sentimentUpdate: $sentimentUpdate
      includeKeywords: $includeKeywords
      excludeKeywords: $excludeKeywords
      validationList: $validationList
      searchType: $searchType
      languageList: $languageList
    ) {
      projectId
      sentimentUpdate
      processedBatches
      totalBatches
      totalData
      processedData
      status
      id
    }
  }
`;

export const ADD_LABEL_ARTICLE = gql`
  mutation labellingArticle_addLabelArticle(
    $projectId: Id!
    $name: String!
    $requestColor: String!
  ) {
    labellingArticle_addLabelArticle(
      projectId: $projectId
      name: $name
      requestColor: $requestColor
    ) {
      id
      name
      displayName
      colorType
      isActive
      createdAt
      __typename
    }
  }
`;

export const EDIT_LABEL_ARTICLE = gql`
  mutation labellingArticle_updateLabelArticle(
    $projectId: Id!
    $name: String!
    $requestColor: String!
    $labelId: Id!
  ) {
    labellingArticle_updateLabelArticle(
      projectId: $projectId
      name: $name
      requestColor: $requestColor
      labelId: $labelId
    ) {
      id
      name
      displayName
      colorType
      isActive
      createdAt
    }
  }
`;

export const DELETE_LABEL_ARTICLE = gql`
  mutation labellingArticle_deleteLabelArticle($labelId: Id!) {
    labellingArticle_deleteLabelArticle(labelId: $labelId) {
      id
      name
      displayName
      colorType
      isActive
      createdAt
    }
  }
`;

export const MANAGE_LABEL_ON_ARTICLE = gql`
  mutation onlineMedia_manageLabelOnArticles(
    $projectId: Id!
    $articlesId: [String!]!
    $attachLabels: [Id!]!
    $removeLabels: [Id!]!
    $clippingId: Id!
  ) {
    onlineMedia_manageLabelOnArticles(
      projectId: $projectId
      articlesId: $articlesId 
      attachLabels: $attachLabels
      removeLabels: $removeLabels
      clippingId: $clippingId
    ) {
      projectId
      attachArticle {
        ${article}
      }
    }
  }
`;

export const MANAGE_LABEL_ON_ARTICLE_BY_PRIMARY = gql`
  mutation onlineMedia_manageLabelOnArticleByFilter(
    $projectId: Id!
    $start: Timestamp!
    $end: Timestamp!
    $mediaList: [String!]!
    $granularity: GranularityInput!
    $timezone: Int!
    $phrases: [String!]
    $sentiment: [Sentiment!]
    $postOwnership: [PostOwnership!]
    $labelList: [Id!]
    $clippingId: Id
    $includeKeywords: [String!]
    $excludeKeywords: [String!]
    $attachLabels: [Id!]!
    $removeLabels: [Id!]!
    $searchType: SearchType
    $languageList: [String!]
  ) {
    onlineMedia_manageLabelOnArticleByFilter(
      projectId: $projectId
      start: $start
      end: $end
      mediaList: $mediaList
      granularity: $granularity
      timezone: $timezone
      phrases: $phrases
      sentiment: $sentiment
      postOwnership: $postOwnership
      labelList: $labelList
      clippingId: $clippingId
      includeKeywords: $includeKeywords
      excludeKeywords: $excludeKeywords
      attachLabels: $attachLabels
      removeLabels: $removeLabels
      searchType: $searchType
      languageList: $languageList
    ) {
      projectId
      attachLabels
      removeLabels
      processedBatches
      totalBatches
      totalData
      processedData
      status
      id
    }
  }
`;

export const ADD_COMPARISON_ONLINE_MEDIA = gql`
  mutation comparisonOnlineMedia_addComparison(
    $projectId: Id!
    $comparisonName: String!
    $comparisonGroupsOnlineMedia: [ComparisonGroupInputOnlineMedia!]!
    $sourceType: SourceTypeOnm!
  ) {
    comparisonOnlineMedia_addComparison(
      projectId: $projectId
      comparisonName: $comparisonName
      comparisonGroupsOnlineMedia: $comparisonGroupsOnlineMedia
      sourceType: $sourceType
    ) {
      id
      namespace
      displayName
      createdAt
      colors
      groups {
        id
        name
        displayName
        createdAt
        clippingList
        labelList
        color
        __typename
      }
      __typename
    }
  }
`;

export const EDIT_COMPARISON_ONLINE_MEDIA = gql`
  mutation comparisonOnlineMedia_editComparison(
    $comparisonId: Id!
    $comparisonName: String!
    $comparisonGroupsOnlineMedia: [ComparisonGroupInputOnlineMedia!]!
  ) {
    comparisonOnlineMedia_editComparison(
      comparisonId: $comparisonId
      comparisonName: $comparisonName
      comparisonGroupsOnlineMedia: $comparisonGroupsOnlineMedia
    ) {
      id
      namespace
      displayName
      createdAt
      colors
      __typename
    }
  }
`;

export const DELETE_COMPARISON_ONLINE_MEDIA = gql`
  mutation comparisonOnlineMedia_deleteComparison($comparisonId: Id!) {
    comparisonOnlineMedia_deleteComparison(comparisonId: $comparisonId) {
      id
      namespace
      displayName
      isDefault
      createdAt
      __typename
    }
  }
`;

export const SET_DEFAULT_ONLINE_MEDIA = gql`
  mutation comparisonOnlineMedia_setDefault(
    $projectId: Id!
    $comparisonId: Id!
  ) {
    comparisonOnlineMedia_setDefault(
      projectId: $projectId
      comparisonId: $comparisonId
    ) {
      id
      namespace
      displayName
      isDefault
      createdAt
      __typename
    }
  }
`;

export const ONLINE_MEDIA_ADD_MEDIA_SOURCE = gql`
  mutation onlineMedia_addOnlineMediaSource($projectId: Id!, $media: String!) {
    onlineMedia_addOnlineMediaSource(projectId: $projectId, media: $media) {
      id
      media
    }
  }
`;

export const ONLINE_MEDIA_ADD_CLIPPING_v5 = gql`
  mutation onlineMedia_addClippingV5(
    $projectId: Id!
    $name: String!
    $phrasesList: [[String!]!]!
    $startingDate: Timestamp!
  ) {
    onlineMedia_addClippingV5(
      projectId: $projectId
      name: $name
      phrasesList: $phrasesList
      startingDate: $startingDate
    ) {
      id
      name
      createdAt
      phrases
      startingDate
      __typename
    }
  }
`;

export const ONLINE_MEDIA_EDIT_CLIPPING_v5 = gql`
  mutation onlineMedia_editClippingV5(
    $clippingId: Id!
    $name: String!
    $phrasesList: [[String!]!]!
    $startingDate: Timestamp!
  ) {
    onlineMedia_editClippingV5(
      clippingId: $clippingId
      name: $name
      phrasesList: $phrasesList
      startingDate: $startingDate
    ) {
      id
      name
      createdAt
      phrases
      startingDate
    }
  }
`;

export const ONLINE_MEDIA_DELETE_CLIPPING_v5 = gql`
  mutation onlineMedia_deleteClippingV5($clippingId: Id!) {
    onlineMedia_deleteClippingV5(clippingId: $clippingId) {
      id
      name
      createdAt
      phrases
      startingDate
    }
  }
`;

// remove all articles stream in primary
export const MANAGE_VISIBILITY_ON_ARTICLE_BY_FILTER = gql`
  mutation onlineMedia_manageVisibilityOnArticleByFilter(
    $projectId: Id!
    $start: Timestamp!
    $end: Timestamp!
    $mediaList: [String!]!
    $granularity: GranularityInput!
    $timezone: Int!
    $phrases: [String!]
    $sentiment: [Sentiment!]
    $postOwnership: [PostOwnership!]
    $labelList: [Id!]
    $clippingId: Id
    $includeKeywords: [String!]
    $visibility: Visibility!
    $excludeKeywords: [String!]
    $validationList: [ValidationType!]
    $searchType: SearchType
    $languageList: [String!]
  ) {
    onlineMedia_manageVisibilityOnArticleByFilter(
      projectId: $projectId
      start: $start
      end: $end
      mediaList: $mediaList
      granularity: $granularity
      timezone: $timezone
      phrases: $phrases
      sentiment: $sentiment
      postOwnership: $postOwnership
      labelList: $labelList
      clippingId: $clippingId
      visibility: $visibility
      includeKeywords: $includeKeywords
      excludeKeywords: $excludeKeywords
      validationList: $validationList
      searchType: $searchType
      languageList: $languageList
    ) {
      projectId
      visibility
      processedBatches
      totalBatches
      totalData
      processedData
      status
      id
    }
  }
`;

export const DOWNLOAD_CLUSTERING_CSV = gql`
  mutation download_createDownloadCsvJobForOnlineMediaClustering(
    $projectId: Id!
    $start: Timestamp!
    $end: Timestamp!
    $mediaList: [String!]!
    $granularity: GranularityInput!
    $timezone: Int!
    $phrases: [String!]
    $includeKeywords: [String!]
    $excludeKeywords: [String!]
    $searchType: SearchType
    $sentiment: [Sentiment!]
    $postOwnership: [PostOwnership!]
    $labelList: [Id!]
    $languageList: [String!]
    $clippingId: Id
    $validationList: [ValidationType!]
    $desiredClusterCount: Int!
    $minimumLabelLength: Int!
    $stopWords: [String!]
  ) {
    download_createDownloadCsvJobForOnlineMediaClustering(
      projectId: $projectId
      start: $start
      end: $end
      mediaList: $mediaList
      granularity: $granularity
      timezone: $timezone
      phrases: $phrases
      includeKeywords: $includeKeywords
      excludeKeywords: $excludeKeywords
      searchType: $searchType
      sentiment: $sentiment
      postOwnership: $postOwnership
      labelList: $labelList
      languageList: $languageList
      clippingId: $clippingId
      validationList: $validationList
      stopWords: $stopWords
      desiredClusterCount: $desiredClusterCount
      minimumLabelLength: $minimumLabelLength
    ) {
      id
      start
      end
      mediaList
      phrase
      status
      createdAt
      link
    }
  }
`;
