/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useMemo } from 'react';
import { Checkbox, Chip, styled, Theme, useTheme } from '@mui/material';
import { TSocialMedia, TStreamType } from 'src/models/general';
import { SxProps } from '@mui/system';
import CheckIcon from '@mui/icons-material/Check';
import { getSymbolByStreamType } from 'src/utils/getSymbolByStreamType';
import GetSocialMediaIcon from '../GetSocialMediaIcon';

export interface Props {
  id: string;
  socialMedia: TSocialMedia;
  name: string;
  streamType?: TStreamType;
  sx?: SxProps<Theme>;
  onChange?: (id: string) => void;
  onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  checked?: boolean;
}

interface ColorChip {
  colorChip: string;
}

const ChipCustom = styled(Chip)<ColorChip>(
  ({ theme, colorChip }) => `
    border: 1px solid rgba(0, 159, 227, 0.2);
    background-color: ${theme.palette.primary.main};
    border-radius: ${theme.spacing(1)};
    padding: 10px ${theme.spacing(1)};
    margin: 0 0 8px 12px;
    height: 29px;
    cursor: pointer;
    &:hover {
      background-color: ${theme.palette.primary.dark};
    }
    .MuiChip-label {
        color: ${colorChip};
        font-size: 14px;
        line-height: 130%;
        margin: 0 8px;
    }
    .MuiChip-avatarSmall {
        width: 14px;
        color: ${colorChip};
    }
    .MuiChip-deleteIcon {
        font-size: 14px;
        color: ${colorChip};
    }
  `
);

const CheckboxCustom = styled(Checkbox)(
  ({ theme }) => `
    &:hover {
      background-color: unset;
    }
    padding: 0
  `
);

const ChipSocialMediaObjectSelector: FC<Props> = ({
  id,
  socialMedia,
  name,
  streamType,
  onChange,
  onClick,
  checked,
  sx
}) => {
  const symbol = useMemo(() => getSymbolByStreamType(streamType), [streamType]);
  const theme = useTheme();
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    //const checked = event.target.checked;
  };
  return (
    <CheckboxCustom
      key={id}
      onChange={handleChange}
      checked={checked}
      onClick={onClick}
      icon={
        <ChipCustom
          variant="filled"
          deleteIcon={<CheckIcon />}
          avatar={
            <GetSocialMediaIcon
              socialMedia={socialMedia}
              sx={{ color: 'white', fontSize: 16 }}
            />
          }
          label={`${symbol}${name}`}
          size="small"
          sx={{
            ...sx,
            background: theme.palette.primary.main
          }}
          colorChip={theme.palette.common.white}
        />
      }
      checkedIcon={
        <ChipCustom
          variant="filled"
          deleteIcon={<CheckIcon />}
          avatar={
            <GetSocialMediaIcon
              socialMedia={socialMedia}
              sx={{ color: theme.colors.primary.main, fontSize: 16 }}
            />
          }
          label={`${symbol}${name}`}
          size="small"
          sx={{
            ...sx,
            background: theme.palette.primary.light
          }}
          colorChip={theme.palette.common.black}
        />
      }
    />
  );
};

export default ChipSocialMediaObjectSelector;
