import { useState, useEffect, useMemo } from 'react';

interface Item {
  id: string;
}

export const useSelectItemDefaultSelected = function <T>(
  dataList: Array<T & Item>,
  dataListIdSelected: Array<string>
) {
  //const dataIdList = useMemo(()=>dataList.map(data=> data.id),[dataList]);
  const [selectedItemByIds, setSelectedItemByIds] =
    useState<Array<string>>(dataListIdSelected);
  useEffect(() => {
    setSelectedItemByIds(dataListIdSelected);
  }, [dataListIdSelected]);

  const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const idList = dataList.map((data) => data.id);
      setSelectedItemByIds(idList);
      return;
    }
    setSelectedItemByIds([]);
  };

  const handleClick = (event: React.MouseEvent<unknown>, id: string) => {
    const selectedIndex = selectedItemByIds.indexOf(id);
    let newSelected: string[] = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedItemByIds, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedItemByIds.slice(1));
    } else if (selectedIndex === selectedItemByIds.length - 1) {
      newSelected = newSelected.concat(selectedItemByIds.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedItemByIds.slice(0, selectedIndex),
        selectedItemByIds.slice(selectedIndex + 1)
      );
    }
    setSelectedItemByIds(newSelected);
  };

  const isSelected = (id: string) => selectedItemByIds.indexOf(id) !== -1;
  const numSelected = selectedItemByIds.length;
  const numOriginalListItem = dataList.length;
  return {
    handleSelectAll,
    setSelectedItemByIds,
    handleClick,
    isSelected,
    numSelected,
    numOriginalListItem,
    selectedItemByIds
  };
};
