import { Box, Grid, Stack, Tab, Tabs, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useAppSelector } from 'src/app/hooks';
import BottomHeader from './Bottom';
import { useLocation, useNavigate } from 'react-router';
import ObjectPreview from 'src/components/ObjectPreview';
import { ONLINE_MEDIA_CLIPPING } from 'src/route';
import GroupObject from 'src/components/GroupObject';
import ModalManageObjectByGroup from 'src/components/ModalManageObjectByGroup';
import { useEffect, useMemo, useState } from 'react';
import useQueryUrl from 'src/hooks/useQuery';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import TooltipCustomize from 'src/components/TooltipCustomize';
import TabNetworkAnalysis from 'src/components/TabNetworkAnalysis';

const HeaderWrapper = styled(Box)(
  ({ theme }) => `
    color: ${theme.header.textColor};
    padding: ${theme.spacing(1, 4, 0, 4)};
    right: 0;
    z-index: 5;
    box-shadow: ${theme.header.boxShadow};
    justify-content: space-between;
    width: 100%;
    background: ${theme.header.background};
    @media (min-width: ${theme.breakpoints.values.lg}px) {
        left: ${theme.sidebar.width};
        width: auto;
    }
`
);

const HeaderWrapperSec = styled(Box)(
  ({ theme }) => `
    background: #fbfbfb;
    color: ${theme.header.textColor};
    padding: ${theme.spacing(1, 4, 0, 4)};
    right: 0;
    z-index: 5;
    justify-content: space-between;
    width: 100%;
    @media (min-width: ${theme.breakpoints.values.lg}px) {
        left: ${theme.sidebar.width};
        width: auto;
    }
`
);

function Header() {
  const { pathname } = useLocation();
  const title = pathname.split('/')[2];
  const query = useQueryUrl();
  const tabQuery = query.get('tab');
  const navigate = useNavigate();
  const [tab, setTab] = useState<string>(tabQuery);
  const setTitle = () => {
    switch (title) {
      case 'social_media':
        return 'Social Media Stream';
      default:
        return title.charAt(0).toUpperCase() + title.slice(1);
    }
  };

  const { objectListByObjectType } = useAppSelector(
    (state) => state.objectManagement
  );

  const objectListPrevies = useMemo(() => {
    if (title === 'content' || title === 'community') {
      return objectListByObjectType.filter(
        (object) => object.streamType === 'account'
      );
    }
    return objectListByObjectType;
  }, [objectListByObjectType, title]);

  const handleChange = (_: React.SyntheticEvent, newValue: string) => {
    setTab(newValue);
    navigate(`${pathname}?tab=${newValue}`);
  };

  useEffect(() => {
    setTab(tabQuery);
  }, [tabQuery]);

  const textTooltip = () => {
    switch (title.toLowerCase()) {
      case 'overview':
        return 'Overview metrics of object performance';
      case 'content':
        return 'Discover engagement performance of social profiles';
      case 'community':
        return 'Discover followers analysis and its growth of social profiles';
      case 'campaign':
        return 'Discover Hashtags performance';
      case 'conversation':
        return 'Discover all mentions and sentiment analysis of social profiles, keywords and hashtags';
      case 'comparison':
        return 'Comparison analysis between each object, group of object or label';
      default:
        return '';
    }
  };

  const isNetworkAnalysis = () => {
    return title === 'sna' || title === 'dna';
  };

  const isClustering = () => {
    return title === 'clustering';
  };

  return (
    <>
      <HeaderWrapper display="flex" alignItems="center">
        <Grid container>
          <Grid item md={12}>
            <Stack direction="row" alignItems="center">
              <Typography
                variant="h1"
                sx={{
                  mb: pathname !== ONLINE_MEDIA_CLIPPING ? 0 : 2,
                  mr: 1
                }}
              >
                {setTitle()}
              </Typography>
              <TooltipCustomize title={textTooltip()} placement="top-start">
                <InfoOutlinedIcon
                  fontSize="small"
                  sx={{ transform: 'translateY(-4px)' }}
                />
              </TooltipCustomize>
            </Stack>
          </Grid>
          <Grid item md={12} width="inherit">
            <GroupObject />
          </Grid>
          <Grid item md={12}>
            <Stack
              direction="row"
              alignItems="center"
              spacing={1}
              mt={1.5}
              mb={2.1}
            >
              <ModalManageObjectByGroup title="Social Profile" />
              <ObjectPreview objectList={objectListPrevies} />
            </Stack>
          </Grid>
          {!isClustering() ? (
            <Grid item md={12}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                {isNetworkAnalysis() ? (
                  <TabNetworkAnalysis
                    tab={tab}
                    handleChange={handleChange}
                    label={title}
                  />
                ) : (
                  <Tabs
                    value={tab !== 'stream_preview' ? 'widget' : tab}
                    onChange={handleChange}
                    aria-label="basic tabs example"
                  >
                    <Tab label="Widget" value="widget" />
                    <Tab label="Stream Preview" value="stream_preview" />
                  </Tabs>
                )}
              </Box>
            </Grid>
          ) : null}
        </Grid>
      </HeaderWrapper>
      <HeaderWrapperSec display="flex" alignItems="center">
        <Grid container>
          <Grid item sm={12}>
            <BottomHeader key={title} />
          </Grid>
        </Grid>
      </HeaderWrapperSec>
    </>
  );
}

export default Header;
