import gql from 'graphql-tag';
import { analyticArguments, analyticArgumentsQuery } from '../analyticArgument';

const analyticArgumentsQueryMedia = `
  $projectId: Id!,
  $start: Timestamp!, 
  $end: Timestamp!, 
  $mediaList: [String!]!, 
  $granularity: GranularityInput!, 
  $timezone: Int!, 
  $phrases: [String!],
  $includeKeywords: [String!],
  $excludeKeywords: [String!],
  $searchType: SearchType
  $sentiment: [Sentiment!], 
  $postOwnership: [PostOwnership!],
  $labelList: [Id!],
  $languageList: [String!],
  $clippingId: Id,
  $validationList: [ValidationType!]
  `;

const analyticArgumentsMedia = `
  projectId: $projectId, 
  start: $start, 
  end: $end, 
  mediaList: $mediaList, 
  granularity: $granularity, 
  timezone: $timezone, 
  phrases: $phrases,
  includeKeywords: $includeKeywords,
  excludeKeywords: $excludeKeywords,
  searchType: $searchType,
  sentiment: $sentiment, 
  postOwnership: $postOwnership,
  labelList: $labelList,
  languageList: $languageList,
  clippingId: $clippingId,
  validationList: $validationList
  `;

const analyticArgumentsQueryMediaOffline = `
  $projectId: Id!,
  $start: Timestamp!, 
  $end: Timestamp!, 
  $mediaList: [String!]!, 
  $granularity: GranularityInput!, 
  $timezone: Int!, 
  $phrase: String!, 
  $sentiment: [Sentiment!], 
  $postOwnership: [PostOwnership!]
  $labelList: [Id!]`;

const analyticArgumentsMediaOffline = `
  projectId: $projectId, 
  start: $start, 
  end: $end, 
  mediaList: $mediaList, 
  granularity: $granularity, 
  timezone: $timezone, 
  phrase: $phrase, 
  sentiment: $sentiment, 
  postOwnership: $postOwnership
  labelList: $labelList`;

export const DOWNLOAD_CSV = gql`
  mutation download_createDownloadCsvJob(${analyticArgumentsQuery}, $email: String!, $templateName: DownloadTemplate,) {
    download_createDownloadCsvJob(${analyticArguments},email: $email, templateName: $templateName ) {
      id
      start
      end
      objectIdList
      status
      createdAt
    }
  }
`;

export const DOWNLOAD_CSV_MEDIA_ONLINE = gql`
  mutation download_createDownloadCsvJobForOnlineMedia(${analyticArgumentsQueryMedia}) {
    download_createDownloadCsvJobForOnlineMedia(${analyticArgumentsMedia}) {
      id
      start
      end
      mediaList
      phrase
      status
      createdAt
      link
    }
  }
`;

export const DOWNLOAD_CSV_JOB_FOR_MEDIA_ONLINE = gql`
  mutation download_createDownloadCsvJobForOnlineMedia(${analyticArgumentsQueryMedia}) {
    download_createDownloadCsvJobForOnlineMedia(${analyticArgumentsMedia}) {
      id
      start
      end
      mediaList
      phrase
      status
      createdAt
      link
    }
  }
`;

// export const DOWNLOAD_CSV_MEDIA_OFFLINE = gql`
//   mutation download_createDownloadCsvJobForOfflineMedia(${analyticArgumentsQueryMediaOffline}) {
//     download_createDownloadCsvJobForOfflineMedia(${analyticArgumentsMediaOffline}) {
//       id
//       start
//       end
//       mediaList
//       phrase
//       status
//       createdAt
//       link
//     }
//   }
// `;
