/* eslint-disable react-hooks/exhaustive-deps */
import {
  Badge,
  Button,
  Drawer,
  Fab,
  Stack,
  styled,
  Typography,
  useTheme
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import ClearAllIcon from '@mui/icons-material/ClearAll';
import ListAltIcon from '@mui/icons-material/ListAlt';
import { Box } from '@mui/system';
import { INotification } from 'src/models/general';
import { CHECK_NOTIFICATION } from 'src/services/graphql/notification/query';
import { useLazyQuery, useMutation } from '@apollo/client';
import { useAppSelector } from 'src/app/hooks';
import ItemActivity from './ItemActivity';
import {
  DELETE_ALL_NOTIFICATION,
  DELETE_NOTIFICATION
} from 'src/services/graphql/notification/mutation';
import CircularProgress from '@mui/material/CircularProgress';
import TooltipCustomize from 'src/components/TooltipCustomize';
import { useDispatch } from 'react-redux';
import { downloadState, downloadBtnState } from 'src/features/download';

const FabCustom = styled(Fab)(
  ({ theme }) => `
      background: linear-gradient(90deg, #DDF5FF 0%, #C1FEFA 50.65%, #C1ECFE 100%);
      opacity: 0.7;
      border: 1px solid rgba(0, 0, 0, 0.15);
      color: ${theme.palette.primary.main};
  `
);

const Activity: React.FC = () => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const projectId = useAppSelector((state) => state.users.project.id);
  const [anchor, setAnchor] = useState<boolean>(false);
  const [progresses, setProgresses] = useState<Array<INotification>>([]);
  const [activity, setActivity] = useState({
    isInvisible: false,
    activityLength: 0
  });
  const showDownload = useAppSelector((state) => state.downloadState.isShow);
  const [getNotification] = useLazyQuery(CHECK_NOTIFICATION, {
    onCompleted: (response) => {
      if (!response) return;
      setProgresses(response?.notification_getNotification);
    }
  });

  useEffect(() => {
    const timer = setInterval(() => {
      getNotification({
        variables: { projectId }
      });
    }, 10 * 1000);
    return () => {
      clearInterval(timer);
    };
  }, [projectId, getNotification]);

  const [deleteNotification, resDeleteNotification] =
    useMutation(DELETE_NOTIFICATION);
  const handleDelete = function (id: string) {
    deleteNotification({
      variables: {
        notificationId: id
      }
    }).then(({ data }) => {
      setProgresses((prevProgresses) =>
        prevProgresses.filter(
          (progress) => progress.id !== data.notification_deleteNotification.id
        )
      );
    });
  };

  const [deleteAllNotification, resDeleteAllNotification] = useMutation(
    DELETE_ALL_NOTIFICATION
  );
  const handleDeleteAll = function () {
    deleteAllNotification({
      variables: {
        projectId
      }
    }).then(() => {
      setProgresses(() => []);
      dispatch(downloadBtnState(true));
    });
  };

  useEffect(() => {
    const statusActivity = progresses.filter(
      (status) => status.status === 'pending'
    );
    setActivity({ isInvisible: false, activityLength: statusActivity.length });
  }, [progresses]);

  useEffect(() => {
    const statusDownload = progresses.filter(
      (status) =>
        status.status === 'pending' && status.typeNotification === 'download'
    );
    if (statusDownload.length > 0) {
      dispatch(downloadBtnState(false));
    } else {
      dispatch(downloadBtnState(true));
    }
  }, [activity]);

  const handleClickActivity = () => {
    setAnchor(true);
    setActivity({ ...activity, isInvisible: true });
    dispatch(downloadState(false));
  };
  return (
    <>
      <Stack
        direction={'row'}
        alignItems={'center'}
        spacing={theme.spacing(1.5)}
      >
        {showDownload ? (
          <>
            <Typography
              sx={{ mb: theme.spacing(0.5), fontWeight: 600 }}
              variant="subtitle2"
            >
              Downloading
            </Typography>

            <Box sx={{ display: 'flex' }}>
              <CircularProgress size={15} disableShrink />
            </Box>
          </>
        ) : null}
        <TooltipCustomize title="List activity" placement="top-start">
          <FabCustom
            size="small"
            sx={{ width: theme.spacing(4.4), height: theme.spacing(4.4) }}
            onClick={handleClickActivity}
          >
            <Badge
              badgeContent={activity.activityLength}
              color="error"
              // invisible={activity.isInvisible}
              // sx={{ '& .MuiBadge-badge': { height: 20, width: 20 } }}
            >
              <ListAltIcon sx={{ fontSize: theme.spacing(3) }} />
            </Badge>
          </FabCustom>
        </TooltipCustomize>
      </Stack>
      <Drawer anchor="right" open={anchor} onClose={() => setAnchor(false)}>
        <Box
          sx={{
            width: theme.spacing(45.5),
            p: theme.spacing(2),
            background: theme.palette.grey[100]
          }}
        >
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography
              variant="h3"
              sx={{ fontWeight: 400, fontSize: '18px', color: '#4B4B4B' }}
            >
              Activity
            </Typography>
            <Button
              variant="text"
              endIcon={<ClearAllIcon />}
              disabled={resDeleteAllNotification.loading}
              onClick={handleDeleteAll}
            >
              Clear All
            </Button>
          </Stack>
        </Box>
        <Box sx={{ px: theme.spacing(1) }}>
          <ItemActivity
            progresses={progresses}
            onDelete={handleDelete}
            loading={resDeleteNotification.loading}
            onClose={() => setAnchor(false)}
          />
        </Box>
      </Drawer>
    </>
  );
};

export default Activity;
