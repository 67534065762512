export const analyticArgumentsQuery = `
  $projectId: Id!,
  $start: Timestamp!, 
  $end: Timestamp!, 
  $objectList: [Id!]!, 
  $labelList: [Id!],
  $granularity: GranularityInput!, 
  $timezone: Int!, 
  $sentiment: [Sentiment!], 
  $postOwnership: [PostOwnership!],
  $validationList: [StreamValidation!],
  $includeKeywords: [String!],
  $excludeKeywords: [String!],
  $fromUsername: String!,
  $contentTypeList: [ContentType!],
  $sentimentConfidenceLevelMin:BigDecimal!,
  $sentimentConfidenceLevelMax:BigDecimal!,
  $replyToOriginalId: [String!],
  $excludeRt: Boolean,
  $showEmptyLabel: Boolean,
  $queryStringBooleanOperator: QueryStringBooleanOperator
  `;

export const analyticArguments = `
  projectId: $projectId, 
  start: $start, 
  end: $end, 
  objectList: $objectList, 
  labelList: $labelList,
  granularity: $granularity, 
  timezone: $timezone, 
  sentiment: $sentiment, 
  postOwnership: $postOwnership,
  validationList: $validationList,
  includeKeywords: $includeKeywords,
  excludeKeywords: $excludeKeywords,
  fromUsername: $fromUsername,
  contentTypeList: $contentTypeList,
  excludeRt: $excludeRt,
  sentimentConfidenceLevelMin:$sentimentConfidenceLevelMin,
  sentimentConfidenceLevelMax:$sentimentConfidenceLevelMax,
  replyToOriginalId:$replyToOriginalId,
  showEmptyLabel: $showEmptyLabel,
  queryStringBooleanOperator: $queryStringBooleanOperator
  `;

export const analyticArgumentsQueryOnlineMedia = `
  $projectId: Id!,
  $start: Timestamp!, 
  $end: Timestamp!, 
  $mediaList: [String!]!, 
  $granularity: GranularityInput!, 
  $timezone: Int!, 
  $sentiment: [Sentiment!], 
  $labelList: [Id!],
  $clippingId: Id,
  $includeKeywords: [String!],
  $excludeKeywords: [String!],
  $phrases: [String!],
  $languageList:[String!],
  $searchType: SearchType,
  $validationList: [ValidationType!]
  `;

export const analyticArgumentsOnlineMedia = `
  projectId: $projectId, 
  start: $start, 
  end: $end, 
  mediaList: $mediaList, 
  granularity: $granularity, 
  timezone: $timezone, 
  sentiment: $sentiment, 
  labelList: $labelList,
  clippingId: $clippingId,
  includeKeywords: $includeKeywords,
  excludeKeywords: $excludeKeywords,
  phrases: $phrases,
  languageList: $languageList,
  searchType: $searchType,
  validationList: $validationList
  `;

export const analyticArgumentsQueryOfflineMedia = `
  $projectId: Id!,
  $start: Timestamp!, 
  $end: Timestamp!, 
  $mediaList: [String!]!, 
  $granularity: GranularityInput!, 
  $timezone: Int!, 
  $sentiment: [Sentiment!], 
  $labelList: [Id!],
  $clippingId: Id,
  $includeKeywords: [String!],
  $excludeKeywords: [String!],
  $phrases: [String!],
  $languageList:[String!],
  $searchType: SearchType,
  $validationList: [ValidationType!]
  `;

export const analyticArgumentsOfflineMedia = `
  projectId: $projectId, 
  start: $start, 
  end: $end, 
  mediaList: $mediaList, 
  granularity: $granularity, 
  timezone: $timezone, 
  sentiment: $sentiment, 
  labelList: $labelList,
  clippingId: $clippingId,
  includeKeywords: $includeKeywords,
  excludeKeywords: $excludeKeywords,
  phrases: $phrases,
  languageList: $languageList,
  searchType: $searchType,
  validationList: $validationList
  `;
