import Cover from 'src/assets/image/cover-sign_in.jpeg';
import KemhanCover from 'src/assets/image/kemhan-cover.jpeg';
import TniAuCover from 'src/assets/image/tni-au-bg.jpeg';
import GcommCover from 'src/assets/image/supergraphic1.png';

const getCoverImage = () => {
  if (
    window.location.hostname.includes('kemhan') ||
    window.location.hostname.includes('legasysolution') ||
    window.location.hostname.includes('elingjogja')
  ) {
    return KemhanCover;
  } else if (window.location.hostname.includes('gcomm')) {
    return GcommCover;
  } else if (window.location.hostname.includes('tni-au')) {
    return TniAuCover;
  } else {
    return Cover;
  }
};

export default getCoverImage;
