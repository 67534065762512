import {
  Box,
  Button,
  Divider,
  IconButton,
  LinearProgress,
  Stack,
  styled,
  Tooltip,
  tooltipClasses,
  TooltipProps,
  Typography,
  useTheme
} from '@mui/material';
import React, { useMemo, useState } from 'react';
import {
  IDateRangeFormatted,
  IGranularity,
  INotification,
  ITableAtribute,
  TSubTypeNotification
} from 'src/models/general';
import ClearIcon from '@mui/icons-material/Clear';
import { useApolloClient, useLazyQuery } from '@apollo/client';
import { GET_NOTIFICATION_DETAIL_V2 } from 'src/services/graphql/notification/query';
import useContent from 'src/services/useContent';
import { useAppSelector } from 'src/app/hooks';
import { fillParamAdvanceSearchEmpty } from 'src/utils/fillParamAdvanceSearchEmpth';
import { timeAgo } from 'src/utils/timeAgo';
import useCommunity from 'src/services/useCommunity';
import { useLocation } from 'react-router';
import useCampagin from 'src/services/useCampaign';
import useConversations from 'src/services/useConversations';
import useComparison from 'src/services/useComparison';
import useStreams from 'src/services/useStreams';
import useOnlineMedia, {
  IUpdateLabelByFilterParams
} from 'src/services/useOnlineMedia';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import {
  InformationDetailUpdateLabel,
  InformationDetailUpdateSentiment,
  InformationDetailIgnoreStream,
  InformationDetailDownloadMediaOnline,
  InformationDetailDownloadSocialMedia,
  InformationDetailFirstTimeSocialMediaData,
  InformationDetailUpdateContentType
} from 'src/components/notifications';
import { downloadFile } from 'src/utils/downloadByUrl';
import usePeople from 'src/services/usePeople';
import { IAdvanceSearchPeoplePayload } from 'src/models/people';
import { fillPeopleAdvanceSearchParams } from 'src/utils/fillPeopleAdvanceSearchParams';
import InformationDetailUpdateLabelOnm, {
  IUpdateLabelOnmDetail
} from 'src/components/notifications/InformationDetailUpdateLabelOnm';
import InformationDetailUpdateSentimentOnm from 'src/components/notifications/InformationDetailUpdateSentimentOnm';
import InformationDetailIgnoreOnm from 'src/components/notifications/InformationDetailIgnoreOnm';
import moment from 'moment';
interface ItemActivityProps {
  progresses: Array<INotification>;
  onDelete: (id: string) => void;
  loading?: boolean;
  onClose: () => void;
}

interface NotificationSelected {
  id: string;
  subTypeNotification: TSubTypeNotification | undefined;
}

const CustomTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: '50vw',
    background: theme.palette.common.white,
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
    fontSize: 14,
    fontWeight: 0.1,
    letterSpacing: 0.2,
    lineHeight: 1.4,
    webkitFontSmoothing: 'antialiased',
    textRendering: 'optimizeLegibility'
  }
}));

const ItemActivity: React.FC<ItemActivityProps> = ({
  progresses,
  onDelete,
  loading = false,
  onClose
}) => {
  const theme = useTheme();
  const advanceSearchSocialMedia = useAppSelector(
    (state) => state.storeAdvanceSearchSocialMedia
  );
  const projectId = useAppSelector((state) => state.users.project.id);
  const { dateRangeFormatted } = useAppSelector(
    (state) => state.analyticArguments
  );
  const [detail, setDetail] = useState();
  const [notificationSelected, setNotificationSelected] =
    useState<NotificationSelected>({
      id: '',
      subTypeNotification: 'downloadSocialMedia'
    });

  const fillAdvanceSearchSocialMedia = useMemo(() => {
    return fillParamAdvanceSearchEmpty(advanceSearchSocialMedia);
  }, [advanceSearchSocialMedia]);

  const granularity: IGranularity = {
    unit: 'day',
    value: 1
  };

  const initialTableAttribute: ITableAtribute = {
    columnName: 'dateColumn',
    limit: 20,
    page: 1,
    sortingMethod: 'desc'
  };

  const { pathname } = useLocation();
  const pathValue = pathname.split('/')[2];

  const { getContentAllMetric } = useContent();
  const { getCommunityAllMetric } = useCommunity();
  const { getAllMetricCampaign } = useCampagin();
  const { getConversationAllMetric } = useConversations();
  const { getAllMetricCompatisonSosmed } = useComparison();
  const {
    getArticle,
    updateLabelByFilter,
    updateSentimentByFilter,
    removeArticlesByFilter
  } = useOnlineMedia();

  const {
    getStreamOutputWithPagination,
    getIgnoredStreamOutputWithPagination
  } = useStreams();

  const { getPeople, getIgnoredPeopleList } = usePeople();

  const objectIdList = useAppSelector(
    (state) => state.analyticArguments.objectIdList
  );

  const finalPayload = {
    ...fillAdvanceSearchSocialMedia,
    projectId,
    ...dateRangeFormatted,
    objectList: objectIdList,
    granularity,
    timezone: 7,
    ...initialTableAttribute
  };

  const advanceSearchOnlineMedia = useAppSelector(
    (state) => state.storeAdvanceSearchOnlineMedia
  );
  const fillAdvanceSearchOnlineMedia = useMemo(() => {
    return fillParamAdvanceSearchEmpty(advanceSearchOnlineMedia);
  }, [advanceSearchOnlineMedia]);
  const { articleList, languageSelected } = useAppSelector(
    (state) => state.storeOnlineMediaStream
  );

  const clippingId = pathname.split('/')[3];

  const onmFinalPayload = {
    ...fillAdvanceSearchOnlineMedia,
    languageList: languageSelected === 'all-language' ? [] : [languageSelected],
    projectId,
    ...dateRangeFormatted,
    granularity,
    timezone: 7,
    clippingId: clippingId,
    page: articleList.data.page,
    limit: articleList.data.limit
  };

  const { people } = useAppSelector((state) => state.storePeople);

  const stateTablePeople = {
    sizeToFetch: people.data.limit,
    page: people.data.page
  };

  const peopleAdvanceSearch = useAppSelector(
    (state) => state.storePeopleAdvanceSearch
  );

  const peopleAdvanceSearchParams = useMemo(() => {
    return fillPeopleAdvanceSearchParams(peopleAdvanceSearch);
  }, [peopleAdvanceSearch]);

  const peoplePayload: IAdvanceSearchPeoplePayload = {
    projectId: projectId,
    granularity: granularity,
    objectList: objectIdList,
    timezone: 7,
    ...peopleAdvanceSearchParams,
    locationValidationList: [],
    ...stateTablePeople,
    ...dateRangeFormatted
  };

  const [getDetailNotification, responseDetailNotification] = useLazyQuery(
    GET_NOTIFICATION_DETAIL_V2
  );

  const handleRefresh = () => {
    onClose();
    switch (pathValue) {
      case 'content':
        getContentAllMetric(finalPayload);
        return;
      case 'community':
        getCommunityAllMetric(finalPayload);
        return;
      case 'campaign':
        getAllMetricCampaign(finalPayload);
        return;
      case 'conversation':
        getConversationAllMetric(finalPayload);
        return;
      case 'comparison':
        getAllMetricCompatisonSosmed(finalPayload);
        return;
      case 'social_media':
        getStreamOutputWithPagination(finalPayload);
        getIgnoredStreamOutputWithPagination(finalPayload);
        return;
      case 'clipping':
        getArticle(onmFinalPayload);
        return;
      case 'people_panel':
        getPeople(peoplePayload);
        getIgnoredPeopleList(peoplePayload);
        return;
    }
  };

  const client = useApolloClient();
  const handleClickDownload = function (subTypeNotification, id) {
    client
      .query({
        query: GET_NOTIFICATION_DETAIL_V2,
        variables: {
          notificationId: id
        }
      })
      .then((response) => {
        downloadFile(
          response.data.notification_getNotificationDetail[
            subTypeNotification === 'downloadCustomReport'
              ? 'downloadReport'
              : subTypeNotification
          ].link
        );
      });
  };

  const handleRetryUpdateLabelOnm = async (id: string) => {
    try {
      const res = await getDetailNotification({
        variables: { notificationId: id }
      });
      const prevVariables: IUpdateLabelOnmDetail =
        res.data.notification_getNotificationDetail.updateLabelOnm;
      const formattedDateRange = {
        start: moment(prevVariables.start)
          .utcOffset(7)
          .format('YYYY-MM-DD HH:mm:ss'),
        end: moment(prevVariables.end)
          .utcOffset(7)
          .format('YYYY-MM-DD HH:mm:ss')
      };

      const retryVariables: IUpdateLabelByFilterParams = {
        ...prevVariables,
        ...formattedDateRange,
        granularity: { unit: 'day', value: 1 } as IGranularity,
        timezone: 7,
        clippingId: prevVariables.clipping.id
      };
      await updateLabelByFilter({ variables: retryVariables });
    } catch (error) {
      console.log(error);
    }
  };

  const handleRetryUpdateSentimentOnm = async (id: string) => {
    try {
      const res = await getDetailNotification({
        variables: { notificationId: id }
      });
      const prevVariables =
        res.data.notification_getNotificationDetail.updateSentimentOnm;
      const formattedDateRange = {
        start: moment(prevVariables.start)
          .utcOffset(7)
          .format('YYYY-MM-DD HH:mm:ss'),
        end: moment(prevVariables.end)
          .utcOffset(7)
          .format('YYYY-MM-DD HH:mm:ss')
      };

      const retryVariables = {
        ...prevVariables,
        ...formattedDateRange,
        granularity: { unit: 'day', value: 1 } as IGranularity,
        timezone: 7,
        clippingId: prevVariables.clipping.id
      };
      await updateSentimentByFilter({ variables: retryVariables });
    } catch (error) {
      console.log(error);
    }
  };

  const handleRetryUpdateVisibilityOnm = async (id: string) => {
    try {
      const res = await getDetailNotification({
        variables: { notificationId: id }
      });
      const prevVariables =
        res.data.notification_getNotificationDetail.updateVisibilityOnm;
      const formattedDateRange = {
        start: moment(prevVariables.start)
          .utcOffset(7)
          .format('YYYY-MM-DD HH:mm:ss'),
        end: moment(prevVariables.end)
          .utcOffset(7)
          .format('YYYY-MM-DD HH:mm:ss')
      };

      const retryVariables = {
        ...prevVariables,
        ...formattedDateRange,
        granularity: { unit: 'day', value: 1 } as IGranularity,
        timezone: 7,
        clippingId: prevVariables.clipping.id
      };
      await removeArticlesByFilter({ variables: retryVariables });
    } catch (error) {
      console.log(error);
    }
  };

  const setSuccess = (
    subTypeNotification: TSubTypeNotification,
    currentProgress: number,
    totalProgress: number,
    id: string
  ) => {
    switch (subTypeNotification) {
      case 'updateLabel':
        return (
          <Button
            sx={{ pl: 0, pr: 0 }}
            variant="text"
            onClick={() => handleRefresh()}
            size="small"
          >
            Refresh
          </Button>
        );
      case 'updateSentiment':
        return (
          <Button
            sx={{ pl: 0, pr: 0 }}
            variant="text"
            onClick={() => handleRefresh()}
            size="small"
          >
            Refresh
          </Button>
        );
      case 'ignoreStream':
        return (
          <Button
            sx={{ pl: 0, pr: 0 }}
            variant="text"
            onClick={() => handleRefresh()}
            size="small"
          >
            Refresh
          </Button>
        );
      case 'downloadSocialMedia':
        return (
          <Button
            sx={{ pl: 0, pr: 0 }}
            variant="text"
            onClick={() => {
              handleClickDownload(subTypeNotification, id);
            }}
            size="small"
          >
            Download
          </Button>
        );
      case 'downloadOnlineMedia':
        return (
          <Button
            sx={{ pl: 0, pr: 0 }}
            variant="text"
            onClick={() => {
              handleClickDownload(subTypeNotification, id);
            }}
            size="small"
          >
            Download
          </Button>
        );
      case 'downloadOfflineMedia':
        return (
          <Button
            sx={{ pl: 0, pr: 0 }}
            variant="text"
            onClick={() => {
              handleClickDownload(subTypeNotification, id);
            }}
            size="small"
          >
            Download
          </Button>
        );
      case 'downloadCustomDashboard':
        return (
          <Button
            sx={{ pl: 0, pr: 0 }}
            variant="text"
            onClick={() => {
              handleClickDownload(subTypeNotification, id);
            }}
            size="small"
          >
            Download
          </Button>
        );
      case 'firstTimeSocialMediaData':
        return (
          <Button
            sx={{ pl: 0, pr: 0 }}
            variant="text"
            onClick={() => handleRefresh()}
            size="small"
          >
            Refresh
          </Button>
        );
      case 'onDemandOnlineMediaSearch':
        return (
          <Button
            sx={{ pl: 0, pr: 0 }}
            variant="text"
            onClick={() => handleRefresh()}
            size="small"
          >
            Refresh
          </Button>
        );
      case 'downloadCustomReport':
        return (
          <Button
            sx={{ pl: 0, pr: 0 }}
            variant="text"
            onClick={() => {
              handleClickDownload(subTypeNotification, id);
            }}
            size="small"
          >
            Download
          </Button>
        );
      case 'downloadOnlineMediaClustering':
        return (
          <Button
            sx={{ pl: 0, pr: 0 }}
            variant="text"
            onClick={() => {
              handleClickDownload(subTypeNotification, id);
            }}
            size="small"
          >
            Download
          </Button>
        );
      case 'updateAgeRangeEngagedUser':
        return (
          <Button
            sx={{ pl: 0, pr: 0 }}
            variant="text"
            onClick={() => handleRefresh()}
            size="small"
          >
            Refresh
          </Button>
        );
      case 'updateGenderEngagedUser':
        return (
          <Button
            sx={{ pl: 0, pr: 0 }}
            variant="text"
            onClick={() => handleRefresh()}
            size="small"
          >
            Refresh
          </Button>
        );
      case 'updateInterestEngagedUser':
        return (
          <Button
            sx={{ pl: 0, pr: 0 }}
            variant="text"
            onClick={() => handleRefresh()}
            size="small"
          >
            Refresh
          </Button>
        );
      case 'updateLabelEngagedUser':
        return (
          <Button
            sx={{ pl: 0, pr: 0 }}
            variant="text"
            onClick={() => handleRefresh()}
            size="small"
          >
            Refresh
          </Button>
        );
      case 'updateLocationEngagedUser':
        return (
          <Button
            sx={{ pl: 0, pr: 0 }}
            variant="text"
            onClick={() => handleRefresh()}
            size="small"
          >
            Refresh
          </Button>
        );
      case 'ignoreEngagedUser':
        return (
          <Button
            sx={{ pl: 0, pr: 0 }}
            variant="text"
            onClick={() => handleRefresh()}
            size="small"
          >
            Refresh
          </Button>
        );
      case 'updateContentMediaType':
        return (
          <Button
            sx={{ pl: 0, pr: 0 }}
            variant="text"
            onClick={() => handleRefresh()}
            size="small"
          >
            Refresh
          </Button>
        );
      case 'reClipping':
        return (
          <Button
            sx={{ pl: 0, pr: 0 }}
            variant="text"
            onClick={() => handleRefresh()}
            size="small"
          >
            Refresh
          </Button>
        );
      case 'updateLabelOnm':
        // if process not complete
        if (currentProgress < totalProgress) {
          return (
            <Button
              sx={{ pl: 0, pr: 0 }}
              variant="text"
              onClick={() => handleRetryUpdateLabelOnm(id)}
              size="small"
            >
              Retry
            </Button>
          );
        }
        // default behaviour
        return (
          <Button
            sx={{ pl: 0, pr: 0 }}
            variant="text"
            onClick={() => handleRefresh()}
            size="small"
          >
            Refresh
          </Button>
        );
      case 'updateSentimentOnm':
        // if process not complete
        if (currentProgress < totalProgress) {
          return (
            <Button
              sx={{ pl: 0, pr: 0 }}
              variant="text"
              onClick={() => handleRetryUpdateSentimentOnm(id)}
              size="small"
            >
              Retry
            </Button>
          );
        }
        // default behaviour
        return (
          <Button
            sx={{ pl: 0, pr: 0 }}
            variant="text"
            onClick={() => handleRefresh()}
            size="small"
          >
            Refresh
          </Button>
        );
      case 'updateVisibilityOnm':
        // if process not complete
        if (currentProgress < totalProgress) {
          return (
            <Button
              sx={{ pl: 0, pr: 0 }}
              variant="text"
              onClick={() => handleRetryUpdateVisibilityOnm(id)}
              size="small"
            >
              Retry
            </Button>
          );
        }
        // default behaviour
        return (
          <Button
            sx={{ pl: 0, pr: 0 }}
            variant="text"
            onClick={() => handleRefresh()}
            size="small"
          >
            Refresh
          </Button>
        );
      default:
        return <div />;
    }
  };

  const handleMouseOut = () => {
    setNotificationSelected({
      id: '',
      subTypeNotification: undefined
    });
    setDetail(undefined);
  };

  const handleGetDetailNotification = (
    id: string,
    subTypeNotification: TSubTypeNotification
  ) => {
    setNotificationSelected({
      id,
      subTypeNotification
    });
    getDetailNotification({
      variables: {
        notificationId: id
      }
    }).then((response) => {
      if (!response) return;
      setDetail(response.data?.notification_getNotificationDetail);
    });
  };

  const renderProcess = (
    subTypeNotification: TSubTypeNotification | undefined,
    detail
  ) => {
    if (!subTypeNotification) return 'Loading...';
    if (!detail[subTypeNotification]) return 'Loading...';
    switch (subTypeNotification) {
      case 'updateLabel':
        return (
          <InformationDetailUpdateLabel detail={detail[subTypeNotification]} />
        );
      case 'updateSentiment':
        return (
          <InformationDetailUpdateSentiment
            detail={detail[subTypeNotification]}
          />
        );
      case 'ignoreStream':
        return (
          <InformationDetailIgnoreStream detail={detail[subTypeNotification]} />
        );
      case 'downloadSocialMedia':
        return (
          <InformationDetailDownloadSocialMedia
            detail={detail[subTypeNotification]}
          />
        );
      case 'downloadOnlineMedia':
        return (
          <InformationDetailDownloadMediaOnline
            detail={detail[subTypeNotification]}
          />
        );
      case 'downloadOfflineMedia':
        return (
          <InformationDetailDownloadMediaOnline
            detail={detail[subTypeNotification]}
          />
        );
      // case 'downloadCustomReport':
      //   return (
      //     <InformationDetailDownloadCostumReport
      //       detail={detail[subTypeNotification]}
      //     />
      //   );
      case 'firstTimeSocialMediaData':
        return (
          <InformationDetailFirstTimeSocialMediaData
            detail={detail[subTypeNotification]}
          />
        );
      case 'onDemandOnlineMediaSearch':
        return (
          <InformationDetailUpdateSentiment
            detail={detail[subTypeNotification]}
          />
        );
      case 'updateContentMediaType':
        return (
          <InformationDetailUpdateContentType
            detail={detail[subTypeNotification]}
          />
        );
      case 'updateAgeRangeEngagedUser':
        return (
          <div>{JSON.stringify(detail[subTypeNotification], undefined, 2)}</div>
        );
      case 'updateGenderEngagedUser':
        return (
          <div>{JSON.stringify(detail[subTypeNotification], undefined, 2)}</div>
        );
      case 'updateInterestEngagedUser':
        return (
          <div>{JSON.stringify(detail[subTypeNotification], undefined, 2)}</div>
        );
      case 'updateLocationEngagedUser':
        return (
          <div>{JSON.stringify(detail[subTypeNotification], undefined, 2)}</div>
        );
      case 'updateLabelOnm':
        return (
          <InformationDetailUpdateLabelOnm
            detail={detail[subTypeNotification]}
          />
        );
      case 'updateSentimentOnm':
        return (
          <InformationDetailUpdateSentimentOnm
            detail={detail[subTypeNotification]}
          />
        );
      case 'updateVisibilityOnm':
        return (
          <InformationDetailIgnoreOnm detail={detail[subTypeNotification]} />
        );

      default:
        return <div>{subTypeNotification}</div>;
    }
  };

  const getLinearProgressValue = (
    currentProgress: number,
    totalProgress: number,
    status: string
  ): number => {
    // if total progress and current progress value is 0
    if (isNaN((currentProgress * 100) / totalProgress)) {
      return status === 'success' ? 100 : 0;
    }
    return (currentProgress * 100) / totalProgress;
  };

  return (
    <>
      {progresses.map(
        ({
          id,
          title,
          typeNotification,
          subTypeNotification,
          status,
          currentProgress,
          totalProgress,
          createdAt
        }) => {
          return (
            <Box sx={{ pt: theme.spacing(2) }} key={id}>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Stack direction="row" alignItems="center">
                  <Typography variant="subtitle2">
                    {title}
                    <CustomTooltip
                      title={
                        responseDetailNotification.loading
                          ? 'Loading...'
                          : detail
                          ? renderProcess(
                              notificationSelected?.subTypeNotification,
                              detail
                            )
                          : ''
                      }
                      disableFocusListener
                      disableHoverListener
                      disableTouchListener
                      open={notificationSelected.id === id}
                      arrow
                      onMouseOver={() =>
                        handleGetDetailNotification(id, subTypeNotification)
                      }
                      onMouseLeave={handleMouseOut}
                    >
                      <InfoOutlinedIcon
                        sx={{
                          fontSize: 13,
                          color: '#9D9D9C',
                          marginLeft: theme.spacing(1)
                        }}
                      />
                    </CustomTooltip>
                  </Typography>
                </Stack>
                <IconButton
                  size="small"
                  onClick={() => onDelete(id)}
                  disabled={loading}
                >
                  <ClearIcon
                    sx={{
                      width: theme.spacing(2.5),
                      height: theme.spacing(2.5),
                      color: '#9D9D9C'
                    }}
                  />
                </IconButton>
              </Stack>
              <LinearProgress
                variant="determinate"
                value={getLinearProgressValue(
                  currentProgress,
                  totalProgress,
                  status
                )}
                sx={{ my: theme.spacing(0.5) }}
                color={status === 'error' ? 'error' : 'primary'}
              />
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography variant="caption">{timeAgo(createdAt)}</Typography>
                {status === 'pending' || status === 'running' ? (
                  <Typography variant="caption">
                    {status === 'pending' ? 'Pending' : 'Running'}
                    <span
                      style={{
                        fontWeight: 700,
                        color: theme.palette.common.black
                      }}
                    >
                      {` ${getLinearProgressValue(
                        currentProgress,
                        totalProgress,
                        status
                      ).toFixed()}%`}
                    </span>
                  </Typography>
                ) : status === 'error' ? (
                  <Typography
                    variant="caption"
                    sx={{ color: `${theme.colors.error.main}` }}
                  >
                    Failed
                  </Typography>
                ) : (
                  setSuccess(
                    subTypeNotification,
                    currentProgress,
                    totalProgress,
                    id
                  )
                )}
              </Stack>
              <Divider sx={{ my: theme.spacing(1) }} />
            </Box>
          );
        }
      )}
    </>
  );
};

export default ItemActivity;
