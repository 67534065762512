/* eslint-disable react-hooks/exhaustive-deps */
import {
  Autocomplete,
  Box,
  Button,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Popover,
  styled,
  SvgIconTypeMap,
  TextField,
  Typography,
  CircularProgress,
  Avatar
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { FC, useMemo, useRef, useState } from 'react';
import { useDebounce } from 'src/hooks/useDebounce';
import { TSocialMedia } from 'src/models/general';
import { IObject } from 'src/models/objectManagement';
import YouTubeIcon from '@mui/icons-material/YouTube';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import useObjectManagement from 'src/services/useObjectManagement';
import { useAppDispatch, useAppSelector } from 'src/app/hooks';
import {
  IObjectInput,
  ISocialProfileAutoComplete,
  TObjectType
} from 'src/models/objectManagement';
import useOnboarding from 'src/services/useOnboarding';
import { reducerUpdateSocialProfileAutoComplete } from 'src/features/objectManagement';
import Confirmation from '../Confirmation';
import GetSocialMediaIcon from '../GetSocialMediaIcon';
import { getSymbolByStreamType } from 'src/utils/getSymbolByStreamType';
import TiktokIcon from '../TikTokIcon/TikTokIcon';

interface ISocialMedia {
  id: TSocialMedia;
  label: string;
  icon:
    | (OverridableComponent<SvgIconTypeMap<{}, 'svg'>> & {
        muiName: string;
      })
    | any;
}

interface Props {
  objectType?: TObjectType;
  width?: number;
  onClose?: () => void;
}

const CustomButton = styled(Button)(
  ({ theme }) => `
    border: unset;
      border-radius: 6px 0 0 6px;
      background-color: ${theme.palette.grey[200]};
      border: 1px solid ${theme.palette.divider};
      border-color: ${theme.palette.common.black}
      color: ${theme.palette.primary.main};
      &:hover{
        background-color: ${theme.palette.grey[300]};
      }
  
    `
);

const AutoCompleteCustom = styled(Autocomplete)(
  ({ theme }) => `
      .MuiOutlinedInput-root {
        padding: 4px!important;
        border-radius: 0px 6px 6px 0px;
      }
  
      .MuiAutocomplete-clearIndicator {
        display: none;
      }
      .MuiAutocomplete-inputRoot.MuiOutlinedInput-root .MuiAutocomplete-endAdornment {
        right: 72px;
      }
    `
);

const ListItemButtonCustom = styled(ListItemButton)(
  ({ theme }) => `
      &:hover {
        background-color: ${theme.palette.primary.light}
      }
    `
);

const listOptionSocialMedia: Array<ISocialMedia> = [
  {
    id: 'twitter',
    label: 'Twitter',
    icon: TwitterIcon
  },
  {
    id: 'facebook',
    label: 'Facebook',
    icon: FacebookIcon
  },
  {
    id: 'instagram',
    label: 'Instagram',
    icon: InstagramIcon
  },
  {
    id: 'youtube',
    label: 'Youtube',
    icon: YouTubeIcon
  },
  {
    id: 'tiktok',
    label: 'Tiktok',
    icon: TiktokIcon
  }
];

const InputSocialProfile: FC<Props> = ({
  objectType = 'account',
  onClose,
  width
}) => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const refAutoComplete = useRef(null);
  const { getObjectManagementAutoComplete } = useObjectManagement();
  const socialProfileAutoComplete = useAppSelector(
    (state) => state.objectManagement.socialProfileAutoComplete
  );
  const objectList = useAppSelector(
    (state) => state.objectManagement.objectList.data
  );

  const [socialMedia, setSocialMedia] = useState<TSocialMedia>('twitter');
  const [tempSocialProfile, setTempSocialProfile] = useState<
    ISocialProfileAutoComplete | undefined
  >();
  const [openAddConfirmation, setOpenAddConfirmation] =
    useState<boolean>(false);

  const [debouncedState, setDebouncedState] = useDebounce();

  const handleAutocomplete = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.persist();
    const keyword = event.target.value;
    setDebouncedState(() => keyword);
  };

  useMemo(() => {
    if (!debouncedState) {
      dispatch(
        reducerUpdateSocialProfileAutoComplete({
          ...socialProfileAutoComplete,
          data: []
        })
      );
      return;
    }
    // if (!debouncedState) return;
    getObjectManagementAutoComplete({
      socialMedia: socialMedia,
      objectName: debouncedState
    });
  }, [debouncedState]);

  const objectListSocialProfile = useMemo((): Array<IObject> => {
    return objectList.filter((object) => object.streamType === 'account');
  }, [objectList]);

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const handleChangeSocialMedia = (socialMediaId: TSocialMedia) => {
    setSocialMedia(socialMediaId);
    handleClose();
  };

  const handleClickOptionAutoComplete = (
    socialProfile: ISocialProfileAutoComplete
  ) => {
    setTempSocialProfile(socialProfile);
  };

  const project = useAppSelector((state) => state.users.project);
  const { createObjects, responseCreateObjects } = useOnboarding();
  const { getObjectList } = useObjectManagement();

  const handleSubmit = (objectInput: IObjectInput) => {
    const { streamType, ...rest } = objectInput;
    const payload = {
      projectId: project.id,
      streamType: 'account',
      objectInput: [rest]
    };
    createObjects({
      variables: payload
    }).then(() => {
      getObjectList(project.id).then(() => {
        onClose();
      });
    });
  };

  const handleAdd = () => {
    const elementAutoComplete = refAutoComplete.current.getElementsByClassName(
      'MuiAutocomplete-clearIndicator'
    )[0];
    elementAutoComplete.click();
    setTempSocialProfile(undefined);
    const { id, username, displayPicture } = tempSocialProfile;
    const tempObjectInput: IObjectInput = {
      content: id,
      displayPic: displayPicture,
      excludes: [],
      extraContent: username,
      includes: [],
      isHashtag: false,
      languages: '',
      objectName: username,
      socialMedia: socialMedia,
      streamType: objectType
    };
    handleSubmit(tempObjectInput);
  };

  const objectTypeSymbol = useMemo(
    () => getSymbolByStreamType(objectType),
    [objectType]
  );

  const isObjectAlreadyIncluded = (
    socialProfile: string,
    socialMedia: TSocialMedia
  ) => {
    // find if social profile is included in object list
    return objectListSocialProfile.some(
      (object) =>
        object.objectName === socialProfile &&
        object.socialMedia === socialMedia
    );
  };

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row'
        }}
      >
        <CustomButton variant="contained" onClick={handleClick}>
          <GetSocialMediaIcon socialMedia={socialMedia} color="primary" />
          <ArrowDropDownIcon fontSize="small" color="primary" sx={{ ml: 1 }} />
        </CustomButton>
        <AutoCompleteCustom
          ref={refAutoComplete}
          //disableClearable
          id="socialProfile"
          options={socialProfileAutoComplete.data}
          renderOption={(props, option: ISocialProfileAutoComplete) => (
            <ListItem {...props} disablePadding key={option.id}>
              <ListItemIcon>
                <Avatar
                  sx={{
                    width: theme.spacing(4),
                    height: theme.spacing(4)
                  }}
                  alt={option.username}
                  src={option.displayPicture}
                />
              </ListItemIcon>
              <ListItemText primary={option.username} />
            </ListItem>
          )}
          onInputChange={handleAutocomplete}
          onChange={(
            _event: React.ChangeEvent<{}>,
            value: ISocialProfileAutoComplete
          ) => {
            handleClickOptionAutoComplete(value);
          }}
          getOptionLabel={(option: ISocialProfileAutoComplete) => option.query}
          noOptionsText={
            <>
              <span style={{ fontWeight: 'bold' }}>Tip : </span>
              <span>
                {socialMedia === 'instagram'
                  ? 'Enter your username, URL input is not available on Instagram'
                  : 'Enter your social profile URL here for exact results'}
              </span>
            </>
          }
          loading={socialProfileAutoComplete.loading}
          sx={{ width: width ? width : '100%' }}
          // isOptionEqualToValue={(
          //   option: ISocialProfileAutoComplete,
          //   value: string
          // ) => option.username === value}
          renderInput={(params) => (
            <TextField
              fullWidth
              {...params}
              InputProps={{
                ...params.InputProps,
                startAdornment: (
                  <Typography sx={{ ml: '4px' }}>{objectTypeSymbol}</Typography>
                ),
                endAdornment: (
                  <>
                    {socialProfileAutoComplete.loading ? (
                      <CircularProgress
                        color="inherit"
                        size={20}
                        sx={{ mr: 1 }}
                      />
                    ) : (
                      params.InputProps.endAdornment
                    )}

                    <Button
                      variant="contained"
                      sx={{ borderRadius: '4px' }}
                      disabled={
                        !tempSocialProfile || socialProfileAutoComplete.loading
                      }
                      endIcon={
                        responseCreateObjects.loading ? (
                          <CircularProgress size={20} />
                        ) : null
                      }
                      onClick={() =>
                        isObjectAlreadyIncluded(
                          tempSocialProfile.username,
                          socialMedia
                        )
                          ? setOpenAddConfirmation(true)
                          : handleAdd()
                      }
                    >
                      Save
                    </Button>
                  </>
                )
              }}
            />
          )}
        />
      </Box>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
      >
        <List>
          {listOptionSocialMedia.map((socialMedia) => (
            <ListItem disablePadding key={socialMedia.id}>
              <ListItemButtonCustom
                onClick={() => handleChangeSocialMedia(socialMedia.id)}
              >
                <ListItemIcon>{<socialMedia.icon />}</ListItemIcon>
                <ListItemText primary={socialMedia.label} />
              </ListItemButtonCustom>
            </ListItem>
          ))}
        </List>
      </Popover>
      <Confirmation
        title={'Add this object?'}
        labelButton={'Add'}
        message={`The ${
          objectType === 'account' ? 'social profile' : 'keyword/hashtag'
        } you want to add has already been added.`}
        open={openAddConfirmation}
        onClose={() => setOpenAddConfirmation(false)}
        onOk={() => {
          handleAdd();
        }}
      />
    </>
  );
};

export default InputSocialProfile;
