import { ApolloQueryResult } from '@apollo/client';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IDatasetSna, IFiltersState } from 'src/models/networkAnalyst';

interface INetworkAnalystState {
  snaDaSet: ApolloQueryResult<IDatasetSna>;
  snaFiltersState: IFiltersState;
  snaDataReady: boolean;
  dnaDaSet: ApolloQueryResult<IDatasetSna>;
  dnaFiltersState: IFiltersState;
  dnaDataReady: boolean;
}

export const initDataSetSna: IDatasetSna = {
  clusters: [],
  edges: [],
  nodes: [],
  tags: [],
  modularity: 0,
  density: 0
};

const initialState: INetworkAnalystState = {
  snaDaSet: {
    data: initDataSetSna,
    loading: false,
    errors: [],
    networkStatus: 1,
    partial: false
  },
  snaDataReady: false,
  snaFiltersState: {
    clusters: {},
    tags: {}
  },
  dnaDaSet: {
    data: initDataSetSna,
    loading: false,
    errors: [],
    networkStatus: 1,
    partial: false
  },
  dnaDataReady: false,
  dnaFiltersState: {
    clusters: {},
    tags: {}
  }
};

export const networkAnalysist = createSlice({
  name: 'networkAnalysist',
  initialState,
  reducers: {
    reducerLoadingSna: (
      state: INetworkAnalystState,
      action: PayloadAction<boolean>
    ) => {
      state.snaDaSet.loading = action.payload;
    },
    reducerUpdateDataSetSna: (
      state: INetworkAnalystState,
      action: PayloadAction<ApolloQueryResult<IDatasetSna>>
    ) => {
      state.snaDaSet = action.payload;
    },
    reducerUpdateSnaDataReady: (
      state: INetworkAnalystState,
      action: PayloadAction<boolean>
    ) => {
      state.snaDataReady = action.payload;
    },
    reducerUpdateSnaFiltersState: (
      state: INetworkAnalystState,
      action: PayloadAction<IFiltersState>
    ) => {
      state.snaFiltersState = action.payload;
    },
    reducerLoadingDna: (
      state: INetworkAnalystState,
      action: PayloadAction<boolean>
    ) => {
      state.dnaDaSet.loading = action.payload;
    },
    reducerUpdateDataSetDna: (
      state: INetworkAnalystState,
      action: PayloadAction<ApolloQueryResult<IDatasetSna>>
    ) => {
      state.dnaDaSet = action.payload;
    },
    reducerUpdateDnaDataReady: (
      state: INetworkAnalystState,
      action: PayloadAction<boolean>
    ) => {
      state.dnaDataReady = action.payload;
    },
    reducerUpdateDnaFiltersState: (
      state: INetworkAnalystState,
      action: PayloadAction<IFiltersState>
    ) => {
      state.dnaFiltersState = action.payload;
    }
  }
});

export const {
  reducerLoadingSna,
  reducerUpdateDataSetSna,
  reducerUpdateSnaDataReady,
  reducerUpdateSnaFiltersState,
  reducerLoadingDna,
  reducerUpdateDataSetDna,
  reducerUpdateDnaDataReady,
  reducerUpdateDnaFiltersState
} = networkAnalysist.actions;

export default networkAnalysist.reducer;
