import gql from "graphql-tag";

export const OBJECT_MANAGEMENT_GET_AUTOCOMPLETE = gql`
  query objectManagement_getAutocomplete(
    $socialMedia: SocialMedia!
    $objectName: String!
  ) {
    objectManagement_getAutocomplete(
      socialMedia: $socialMedia
      objectName: $objectName
    ) {
      id
      username
      displayPicture
      query
    }
  }
`;

export const GET_OBJECT_LIST = gql`
  query objectManagement_getObjectList($projectId: Id!) {
    objectManagement_getObjectList(projectId: $projectId) {
      id
      socialMedia
      streamType
      content
      extraContent
      objectName
      isHashtag
      createdAt
      openApiId
      displayPicture
      includes
      excludes
      languages
    }
  }
`;

export const OBJECT_MANAGEMENT_GET_STREAM_PREVIEW = gql`
  query objectManagement_getStreamPreview(
    $socialMedia: SocialMedia!
    $objectName: String!
    $includes: [String!]
    $excludes: [String!]
    $languages: [String!]
  ) {
    objectManagement_getStreamPreview(
      socialMedia: $socialMedia
      objectName: $objectName
      includes: $includes
      excludes: $excludes
      languages: $languages
    ) {
      dataView {
        id
        timestamp
        caption
        displayName
        displayPicture
        url
      }
      next
    }
  }
`;

export const OBJECT_MANAGEMENT_GET_GROUP_LIST = gql`
query objectManagement_getObjectGroupList(
  $projectId: Id!
  $templateName: String!
) {
  objectManagement_getObjectGroupList(
    projectId: $projectId
    templateName: $templateName
  ) {
    id
    name
    displayName
    createdAt
    objectList {
      id
      socialMedia
      streamType
      extraContent
      objectName
      isHashtag
    }
  }
}
`;

export const GET_OBJECT_LIMIT = gql`
query objectManagement_getObjectLimit($projectId: Id!) {
  objectManagement_getObjectLimit(projectId: $projectId) {
    accountUsed
    accountQuota
    keywordUsed
    keywordQuota
  }
}
`;