/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Checkbox,
  Chip,
  styled,
  Tooltip,
  tooltipClasses,
  TooltipProps,
  Typography,
  useTheme
} from '@mui/material';
import { IObject } from 'src/models/objectManagement';
import { useAppDispatch, useAppSelector } from 'src/app/hooks';
import { useEffect, useMemo, useState } from 'react';
import ButtonCircle from '../ButtonCircle';
import CheckIcon from '@mui/icons-material/Check';
import { reducerUpdateAnalyticArgumentObjectIdList } from 'src/features/analyticArguments';
import { themeColors } from 'src/theme/schemes/PureLightTheme';
import GetSocialMediaIcon from '../GetSocialMediaIcon';
import { useLocation } from 'react-router-dom';
import { useAlert } from 'src/hooks/useAlert';

interface Props {
  objectList: Array<IObject>;
  visibleAmount?: number;
}

type BoxProps = {
  backgroundColor?: string;
};

const Container = styled(Box)<BoxProps>(
  ({ theme }) => `
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px 11px 8px 11px;
    border-radius:18px;
    background: #FBFBFB;
    border: 1px solid #E8E9EA;
    box-sizing: border-box;
  `
);

const CheckboxCustom = styled(Checkbox)(
  ({ theme }) => `
    &:hover {
      background-color: unset;
    }
    padding: 0
  `
);

interface ColorChip {
  colorChip: string;
}

const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 11
  }
}));

const ChipCustom = styled(Chip)<ColorChip>(
  ({ theme, colorChip }) => `
    margin-right: ${theme.spacing(1)};
    background-color: ${theme.palette.primary.main};
    border-radius: ${theme.spacing(1.43)};
    padding: ${theme.spacing(1)} 0px ${theme.spacing(1)} 2px;
    width: ${theme.spacing(7)};
    height: ${theme.spacing(3)};
    cursor: pointer;
    &:hover {
      background-color: ${theme.palette.primary.dark};
    }
    .MuiChip-label {
        color: ${colorChip};
        font-size: 13px;
        line-height: 130%;
        margin: 8px 0px 8px 8px;
    }
    .MuiChip-avatarSmall {
        width: 14px;
        color: ${colorChip};
        margin: 0px -3px 0px 0px;
    }
    .MuiChip-deleteIcon {
        font-size: 14px;
        color: ${colorChip};
    }
  `
);

const ObjectPreview: React.FC<Props> = ({ objectList, visibleAmount = 3 }) => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const objectIdList = useAppSelector(
    (state) => state.analyticArguments.objectIdList
  );
  const { pathname } = useLocation();
  const title = pathname.split('/')[2];

  const [checkedState, setCheckedState] = useState(new Array(5).fill(false));

  const { handleClickAlert } = useAlert();
  const autoRefreshInterval = useAppSelector(
    (state) => state.autoRefreshMetric.selectedAutoRefreshInterval
  );

  const objectListByObjectTypeActive = useMemo(() => {
    return objectList.filter((object) =>
      objectIdList.find((id) => id === object.id)
    );
  }, [objectIdList, objectList]);

  useEffect(() => {
    setCheckedState((prev) => prev.map(() => false));
  }, [title, objectList]);

  const objectListByTwitter = useMemo(() => {
    return objectList.filter((object) => object.socialMedia === 'twitter');
  }, [objectIdList, objectList]);
  const objectListByFacebook = useMemo(() => {
    return objectList.filter((object) => object.socialMedia === 'facebook');
  }, [objectIdList, objectList]);
  const objectListByInstagram = useMemo(() => {
    return objectList.filter((object) => object.socialMedia === 'instagram');
  }, [objectIdList, objectList]);
  const objectListByYoutube = useMemo(() => {
    return objectList.filter((object) => object.socialMedia === 'youtube');
  }, [objectIdList, objectList]);
  const objectListByTiktok = useMemo(() => {
    return objectList.filter((object) => object.socialMedia === 'tiktok');
  }, [objectIdList, objectList]);

  const handleClickAll = () => {
    if (autoRefreshInterval > 0) {
      handleClickAlert({
        vertical: 'top',
        horizontal: 'center',
        severity: 'warning',
        message: 'You can manage objects only when auto refresh is off'
      });
      return;
    }
    const dataList = objectList.map((object) => {
      return object.id;
    });
    checkedState.map((item, index) =>
      setCheckedState(new Array(5).fill(false))
    );
    dispatch(reducerUpdateAnalyticArgumentObjectIdList(dataList));
  };

  const handleClickSource = (source, position) => {
    if (autoRefreshInterval > 0) {
      handleClickAlert({
        vertical: 'top',
        horizontal: 'center',
        severity: 'warning',
        message: 'You can manage objects only when auto refresh is off'
      });
      return;
    }
    const dataList = source.map((object) => {
      return object.id;
    });
    const dataListActive = objectListByObjectTypeActive.map((object) => {
      return object.id;
    });
    const containsAll = dataList.every((object) => {
      return dataListActive.includes(object);
    });
    const updatedCheckedState = checkedState.map((item, index) =>
      index === position ? !item : item
    );
    setCheckedState(updatedCheckedState);
    if (containsAll) {
      const filteredList = objectIdList.filter((objectId) => {
        return !dataList.includes(objectId);
      });
      dispatch(reducerUpdateAnalyticArgumentObjectIdList(filteredList));
    } else {
      dispatch(
        reducerUpdateAnalyticArgumentObjectIdList([
          ...dataList,
          ...dataListActive
        ])
      );
    }
  };

  return (
    <Container flexDirection="row" alignItems="center">
      <ButtonCircle
        onClick={handleClickAll}
        sx={{
          marginRight: theme.spacing(2),
          background: '#E0F6FF'
        }}
      >
        <LightTooltip
          title={objectList
            .map((object) => {
              return object.objectName;
            })
            .join(', ')}
        >
          <Typography
            sx={{ fontSize: '14px', color: '#009FE3', fontWeight: 600 }}
          >
            All
          </Typography>
        </LightTooltip>
      </ButtonCircle>

      <CheckboxCustom
        onClick={() => handleClickSource(objectListByTwitter, 0)}
        checked={checkedState[0]}
        disabled={objectListByTwitter.length > 0 ? false : true}
        icon={
          <ChipCustom
            variant="filled"
            deleteIcon={<CheckIcon />}
            avatar={
              <GetSocialMediaIcon
                socialMedia={'twitter'}
                sx={{ color: '#FBFBFB', fontSize: 16 }}
              />
            }
            label={objectListByTwitter.length}
            size="small"
            sx={{
              background: `${
                objectListByTwitter.length > 0 ? '#009FE3' : '#DBDBDB'
              }`
            }}
            colorChip={theme.palette.common.white}
          />
        }
        checkedIcon={
          <ChipCustom
            variant="filled"
            deleteIcon={<CheckIcon />}
            avatar={
              <GetSocialMediaIcon
                socialMedia={'twitter'}
                sx={{ color: '#FBFBFB', fontSize: 16 }}
              />
            }
            label={objectListByTwitter.length}
            size="small"
            sx={{
              background: '#DBDBDB'
            }}
            colorChip={theme.palette.common.white}
          />
        }
      />

      <CheckboxCustom
        onClick={() => handleClickSource(objectListByFacebook, 1)}
        checked={checkedState[1]}
        disabled={objectListByFacebook.length > 0 ? false : true}
        icon={
          <ChipCustom
            variant="filled"
            deleteIcon={<CheckIcon />}
            avatar={
              <GetSocialMediaIcon
                socialMedia={'facebook'}
                sx={{ color: '#FBFBFB', fontSize: 16 }}
              />
            }
            label={objectListByFacebook.length}
            size="small"
            sx={{
              background: `${
                objectListByFacebook.length > 0 ? '#3B5998' : '#DBDBDB'
              }`
            }}
            colorChip={theme.palette.common.white}
          />
        }
        checkedIcon={
          <ChipCustom
            variant="filled"
            deleteIcon={<CheckIcon />}
            avatar={
              <GetSocialMediaIcon
                socialMedia={'facebook'}
                sx={{ color: '#FBFBFB', fontSize: 16 }}
              />
            }
            label={objectListByFacebook.length}
            size="small"
            sx={{
              background: '#DBDBDB'
            }}
            colorChip={theme.palette.common.white}
          />
        }
      />

      <CheckboxCustom
        onClick={() => handleClickSource(objectListByInstagram, 2)}
        checked={checkedState[2]}
        disabled={objectListByInstagram.length > 0 ? false : true}
        icon={
          <ChipCustom
            variant="filled"
            deleteIcon={<CheckIcon />}
            avatar={
              <GetSocialMediaIcon
                socialMedia={'instagram'}
                sx={{ color: '#FBFBFB', fontSize: 16 }}
              />
            }
            label={objectListByInstagram.length}
            size="small"
            sx={{
              background: `${
                objectListByInstagram.length > 0 ? '#7A3EAE' : '#DBDBDB'
              }`
            }}
            colorChip={theme.palette.common.white}
          />
        }
        checkedIcon={
          <ChipCustom
            variant="filled"
            deleteIcon={<CheckIcon />}
            avatar={
              <GetSocialMediaIcon
                socialMedia={'instagram'}
                sx={{ color: '#FBFBFB', fontSize: 16 }}
              />
            }
            label={objectListByInstagram.length}
            size="small"
            sx={{
              background: '#DBDBDB'
            }}
            colorChip={theme.palette.common.white}
          />
        }
      />

      <CheckboxCustom
        onClick={() => handleClickSource(objectListByYoutube, 3)}
        checked={checkedState[3]}
        disabled={objectListByYoutube.length > 0 ? false : true}
        icon={
          <ChipCustom
            variant="filled"
            deleteIcon={<CheckIcon />}
            avatar={
              <GetSocialMediaIcon
                socialMedia={'youtube'}
                sx={{ color: '#FBFBFB', fontSize: 16 }}
              />
            }
            label={objectListByYoutube.length}
            size="small"
            sx={{
              background: `${
                objectListByYoutube.length > 0 ? '#FF0000' : '#DBDBDB'
              }`
            }}
            colorChip={theme.palette.common.white}
          />
        }
        checkedIcon={
          <ChipCustom
            variant="filled"
            deleteIcon={<CheckIcon />}
            avatar={
              <GetSocialMediaIcon
                socialMedia={'youtube'}
                sx={{ color: '#FBFBFB', fontSize: 16 }}
              />
            }
            label={objectListByYoutube.length}
            size="small"
            sx={{
              background: '#DBDBDB'
            }}
            colorChip={theme.palette.common.white}
          />
        }
      />
      <CheckboxCustom
        onClick={() => handleClickSource(objectListByTiktok, 4)}
        checked={checkedState[4]}
        disabled={objectListByTiktok.length > 0 ? false : true}
        icon={
          <ChipCustom
            variant="filled"
            deleteIcon={<CheckIcon />}
            avatar={
              <GetSocialMediaIcon
                socialMedia={'tiktok'}
                sx={{ color: '#FBFBFB', fontSize: 16 }}
              />
            }
            label={objectListByTiktok.length}
            size="small"
            sx={{
              background: `${
                objectListByTiktok.length > 0 ? themeColors.tiktok : '#DBDBDB'
              }`
            }}
            colorChip={theme.palette.common.white}
          />
        }
        checkedIcon={
          <ChipCustom
            variant="filled"
            deleteIcon={<CheckIcon />}
            avatar={
              <GetSocialMediaIcon
                socialMedia={'tiktok'}
                sx={{ color: '#FBFBFB', fontSize: 16 }}
              />
            }
            label={objectListByTiktok.length}
            size="small"
            sx={{
              background: '#DBDBDB'
            }}
            colorChip={theme.palette.common.white}
          />
        }
      />
    </Container>
  );
};

export default ObjectPreview;
