// this function is used in sentiment comparison bar chart
export const generateGroupSentiment = function (
  columns: any,
  names: Array<string>
) {
  return names.map((name) => {
    let result = {
      name: name,
      data: [] as any
    };
    columns.forEach((column: any) => {
      Object.keys(column).forEach((key) => {
        if (name === key)
          result.data.push({
            x: column['groupName'],
            y: column[key]
          });
      });
    });
    return result;
  });
};
