/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  FormControl,
  Stack,
  TextField,
  IconButton,
  useTheme,
  Button,
  Select,
  MenuItem,
  Tooltip,
  useMediaQuery
} from '@mui/material';
import { styled } from '@mui/material/styles';
import SearchTwoToneIcon from '@mui/icons-material/SearchTwoTone';
import SelectDateRange from 'src/components/SelectDateRange';
import { useLocation } from 'react-router';
import { KeyboardEvent, useEffect, useMemo, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'src/app/hooks';
import {
  initialState,
  reducerUpdateAdvanceClear,
  reducerUpdateAdvanceSearchIncludeKeywords
} from 'src/features/advanceSearchSocialMedia';
import AdvanceSearchSocialMedia from 'src/components/AdvanceSearchSocialMedia';
import ClearIcon from '@mui/icons-material/Clear';
import DateRangeLabel from '../../components/DateRangeLabel';
import FilterListIcon from '@mui/icons-material/FilterList';
import changeObjectToQuery from 'src/utils/changeObjectToQuery';
import TooltipCustomize from 'src/components/TooltipCustomize';
import RefreshIcon from '@mui/icons-material/Refresh';
import { fillParamAdvanceSearchEmpty } from 'src/utils/fillParamAdvanceSearchEmpth';
import { IGranularity, ITableAtribute } from 'src/models/general';
import useContent from 'src/services/useContent';
import useCommunity from 'src/services/useCommunity';
import useConversations from 'src/services/useConversations';
import useOverview from 'src/services/useOverview';
import SelectAutoRefreshInterval from 'src/components/SelectAutoRefreshInterval';

import {
  reducerUpdateAnalyticArgumentObjectIdList,
  reducerUpdateMaxCountNode
} from 'src/features/analyticArguments';
import useObjectManagement from 'src/services/useObjectManagement';
import { reducerUpdateObjectListByObjectType } from 'src/features/objectManagement';
import { useAlert } from 'src/hooks/useAlert';
import { IObject } from 'src/models/objectManagement';
import { concatUniqueObjectsById } from 'src/utils/concatUniqueObjectsById';

const limitations = [1, 3, 5, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100];

function BottomHeader() {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();
  const { handleClickAlert } = useAlert();
  const title = pathname.split('/')[2];

  const isLargeScreen = useMediaQuery(theme.breakpoints.up('md'));

  const advanceSearch = useAppSelector(
    (state) => state.storeAdvanceSearchSocialMedia
  );

  const {
    streamsCommentingToPost,
    selectedOriginalToPost,
    searchOriginalToPost,
    ...rest
  } = advanceSearch;
  const query = changeObjectToQuery(rest);
  const [localSearch, setLocalSearch] = useState<string>(query);

  const handleKeyDown = (event: KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') handleSearch();
  };

  const handleClear = () => {
    setLocalSearch('');
    dispatch(reducerUpdateAdvanceClear(initialState));
  };

  const handleSearch = () => {
    dispatch(reducerUpdateAdvanceSearchIncludeKeywords([localSearch]));
  };

  const handleChangeMaxCountNode = (value: string | number) => {
    dispatch(reducerUpdateMaxCountNode(value));
  };
  const [open, setOpen] = useState<boolean>(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    setLocalSearch(query);
  }, [advanceSearch]);

  const projectId = useAppSelector((state) => state.users.project.id);

  const advanceSearchSocialMedia = useAppSelector(
    (state) => state.storeAdvanceSearchSocialMedia
  );

  const fillAdvanceSearchSocialMedia = useMemo(() => {
    return fillParamAdvanceSearchEmpty(advanceSearchSocialMedia);
  }, [advanceSearchSocialMedia]);

  const { dateRangeFormatted, objectIdList, maxCountNode } = useAppSelector(
    (state) => state.analyticArguments
  );

  const objectList = useAppSelector(
    (state) => state.objectManagement.objectList.data
  );

  const objectIds = useMemo(
    () => objectList.map((object) => object.id),
    [objectList]
  );

  const { groupSelectedById: selectedGroupId, groupObject: prevGroupObject } =
    useAppSelector((state) => state.objectManagement);

  const objectListByObjectTypeActive = useMemo(() => {
    const dataList = objectList.filter((object) =>
      objectIdList.find((id) => id === object.id)
    );
    return dataList.map((object) => {
      return object.id;
    });
  }, [objectIdList]);

  const granularity: IGranularity = {
    unit: 'day',
    value: 1
  };

  const initialTableAttribute: ITableAtribute = {
    columnName: 'dateColumn',
    limit: 20,
    page: 1,
    sortingMethod: 'desc'
  };

  const finalPayload = {
    ...fillAdvanceSearchSocialMedia,
    projectId,
    ...dateRangeFormatted,
    objectList: objectListByObjectTypeActive,
    granularity,
    timezone: 7,
    ...initialTableAttribute
  };

  const { getContentAllMetric } = useContent();
  const { getCommunityAllMetric } = useCommunity();
  const { getConversationAllMetric } = useConversations();
  const { getAllService } = useOverview();

  const { getObjectList, getGroupObject } = useObjectManagement();

  const handleRefresh = () => {
    Promise.all([
      getObjectList(projectId),
      getGroupObject({ projectId: projectId, templateName: 'content' })
    ])
      .then((responses) => {
        // if a group is selected
        if (selectedGroupId !== '_default') {
          const newGroupObjectList = responses[1].data;
          const prevSelectedGroup = prevGroupObject.data.find(
            (group) => group.id === selectedGroupId
          );
          const prevSelectedGroupIds = prevSelectedGroup.objectList.map(
            (group) => group.id
          );
          const newSelectedGroup = newGroupObjectList.find(
            (group) => group.id === selectedGroupId
          );

          // semua object yang ada di active object + object baru di grup
          const finalActiveObjects = newSelectedGroup.objectList.filter(
            (object) =>
              objectIdList.includes(object.id) ||
              !prevSelectedGroupIds.includes(object.id)
          );
          dispatch(reducerUpdateObjectListByObjectType(finalActiveObjects));
          dispatch(
            reducerUpdateAnalyticArgumentObjectIdList(
              finalActiveObjects.map((object) => object.id)
            )
          );
          // if no group selected
        } else {
          const newObjectList = responses[0].data;
          // get only new objects
          const newObjects = newObjectList.filter((object) => {
            return !objectIds.includes(object.id);
          });

          const selectedObjects = newObjectList.filter((object) =>
            objectIdList.includes(object.id)
          );
          const finalActiveObjects = concatUniqueObjectsById(
            selectedObjects,
            newObjects
          );

          dispatch(reducerUpdateObjectListByObjectType(finalActiveObjects));
          dispatch(
            reducerUpdateAnalyticArgumentObjectIdList(
              finalActiveObjects.map((object) => object.id)
            )
          );
        }

        // switch (title) {
        //   case 'overview':
        //     getAllService(finalPayload);
        //     return;
        //   case 'content':
        //     getContentAllMetric(finalPayload);
        //     return;
        //   case 'community':
        //     getCommunityAllMetric(finalPayload);
        //     return;
        //   case 'conversation':
        //     getConversationAllMetric(finalPayload);
        //     return;
        // }
      })
      .catch((error) =>
        handleClickAlert({
          vertical: 'top',
          horizontal: 'center',
          message: "Couldn't fetch data, please try again."
        })
      );
  };

  // auto refresh metric
  const { selectedAutoRefreshInterval } = useAppSelector(
    (store) => store.autoRefreshMetric
  );
  useEffect(() => {
    if (selectedAutoRefreshInterval === 0) return;
    const interval = setInterval(() => {
      handleRefresh();
    }, selectedAutoRefreshInterval);

    return () => clearInterval(interval);
  }, [selectedAutoRefreshInterval]);

  return (
    <Stack direction="column" my={2}>
      <Stack
        direction={isLargeScreen ? 'row' : 'column'}
        justifyContent={isLargeScreen ? 'space-between' : 'unset'}
        gap={isLargeScreen ? 3 : 2}
      >
        <Stack
          direction="row"
          alignItems="center"
          spacing={2}
          className="filtering"
          width="100%"
          maxWidth={isLargeScreen ? '600px' : 'unset'}
        >
          <TextField
            size="small"
            InputProps={{
              startAdornment: (
                <IconButton size="small" onClick={handleSearch}>
                  <SearchTwoToneIcon />
                </IconButton>
              ),
              endAdornment: localSearch ? (
                <IconButton size="small" onClick={handleClear}>
                  <ClearIcon />
                </IconButton>
              ) : null
            }}
            placeholder="Search stream"
            onChange={(e) => setLocalSearch(e.target.value)}
            value={localSearch}
            onKeyDown={handleKeyDown}
            fullWidth
          />
          <TooltipCustomize title="Advance search" placement="top-start">
            <Button
              variant="contained"
              sx={{
                borderRadius: '6px',
                height: theme.spacing(5),
                minWidth: theme.spacing(6),
                background: theme.palette.primary.dark
              }}
              onClick={() => handleClickOpen()}
            >
              <FilterListIcon htmlColor={theme.colors.primary.lighter} />
            </Button>
          </TooltipCustomize>
          {open && (
            <AdvanceSearchSocialMedia open={open} onClose={handleClose} />
          )}
        </Stack>
        <Stack
          direction="row"
          justifyContent={isLargeScreen ? 'unset' : 'space-between'}
          spacing={2}
          className="timeframeAndRefresh"
        >
          {title === 'sna' ||
            (title === 'dna' && (
              <Tooltip title="Limitation value node" placement="top-start">
                <FormControl size="small" sx={{ minWidth: 100 }}>
                  <Select
                    id="limit-value"
                    name="limit-value"
                    value={maxCountNode}
                    onChange={(e) => handleChangeMaxCountNode(e.target.value)}
                  >
                    {limitations.map((limit) => (
                      <MenuItem key={limit} value={limit}>
                        {limit}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Tooltip>
            ))}
          <FormControl size="small" sx={{ width: 135 }}>
            <SelectDateRange />
          </FormControl>
          <Stack direction="row" gap={1}>
            {title !== 'clustering' && (
              <TooltipCustomize
                title="Refresh all metric"
                placement="top-start"
              >
                <Button
                  variant="contained"
                  onClick={handleRefresh}
                  sx={{
                    borderRadius: '6px',
                    height: theme.spacing(5),
                    minWidth: theme.spacing(6),
                    background: theme.palette.primary.main
                  }}
                >
                  <RefreshIcon htmlColor={theme.colors.primary.lighter} />
                </Button>
              </TooltipCustomize>
            )}
            <FormControl size="small" sx={{ width: 100 }}>
              <SelectAutoRefreshInterval />
            </FormControl>
          </Stack>
        </Stack>
      </Stack>
      <DateRangeLabel label={`Viewing ${title} within`} />
    </Stack>
  );
}

export default BottomHeader;
